import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {FormItemSelectionList} from "./components/formItemModal/FormItemSelectionList";


export const FormItemSelectionModal = (props, ref) => {
    const [selected, setSelected] = useState("");
    const [localItem, setLocalItem] = useState();

    useEffect(() => {
        setLocalItem({inputType: selected})
    }, [selected])


    const onEnter = () => {
        setSelected("")
    }

    const selectItem = (key) => {
        setSelected(key);
    }

    const afterClose = () => {
        setSelected("");
    }

    const selectAndEdit = () => {
        props.addItem(selected, localItem);
        props.closeModal();
    }

    const onChange = (changedItem) => {
        setLocalItem(changedItem);
    }

    const editAndClose = (changedItem) => {
        props.addItem(selected, localItem);
        props.closeModal();
    }

    return (
        <Modal
            show={props.visible}
            onEnter={onEnter}
            onHide={afterClose}
            onCancel={props.onCancel}
            scrollable={true}
        >
            <Modal.Header>
                <Modal.Title>{"Select new Form-Item"}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormItemSelectionList active={true} selectItem={selectItem} selected={selected}/>
            </Modal.Body>
            <Modal.Footer>
                <Button key={"cancel"} onClick={props.closeModal} variant={"secondary"}>
                    Cancel
                </Button>

                <Button key={"editAndClose"} hidden={false} type="primary" onClick={editAndClose}>
                    Add Field and edit
                </Button>
            </Modal.Footer>

        </Modal>
    )
};
