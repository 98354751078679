import * as ACTION_TYPES from "../actions/errorActions";
import produce from "immer"



function errorReducer(state = {}, action) {

    if (action.type === ACTION_TYPES.ON_ERROR) {
        return produce(state, error => {
            let errs = action.error;
            error.status = errs.status;
            error.message = errs.message;
            error.errors = errs.errors;
            error.showMessage = errs.showMessage;
            error.raw = errs.raw;
        })
    }

    if (action.type === ACTION_TYPES.CLEAR_ERRORS) {
        return produce(state, error => {
            for(let k in error){
                delete error[k];
            }
        })
    }

    return state;
}

export default errorReducer;
