import React, {useState, useEffect} from "react";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const TYPE = "inputType";
const RANGE = "range";


const NumberInputConfig = (props) => {
    const {item, onChange} = props;

    const [valueInputType, setValueInputType] = useState(item.config?.[TYPE] || "INPUT_BOX");
    const [valueRange, setValueRange] = useState(item.config?.[RANGE] || [0, 10]);


    const onUpdate = () => {

        const numberInput = {
            [TYPE]: valueInputType,
            [RANGE]: valueRange
        }

        onChange(numberInput)
    }

    useEffect(() => {
        onUpdate();
    }, [valueRange, valueInputType]);

    const onInputChange = (e) => {
        setValueInputType(e.target.value);
        onUpdate();
    }

    const onRangeChange = (pos, e) => {
        let val = parseInt(e.target.value);
        let tempValueRange = valueRange.slice(0);
        tempValueRange[pos] = val;
        setValueRange(tempValueRange);
        onUpdate();
    }

    return (
        <>
                <Col xs={6}>

                    <Form.Label>Min</Form.Label>
                    <br/>
                    <Form.Control type={"number"} value={valueRange[0]}
                                  onChange={(v) => onRangeChange(0, v)}/>


                </Col>
                <Col xs={6}>

                    <Form.Label>Max</Form.Label>
                    <br/>
                    <Form.Control type={"number"} value={valueRange[1]}
                                  onChange={(v) => onRangeChange(1, v)}/>

                </Col>

                <Col xs={12} className={"mt-3"}>
                    <Form.Group onChange={onInputChange}>
                        <FormCheck.Label>{"Which Type"} </FormCheck.Label>
                        <br/>
                        <Form.Check checked={valueInputType === "INPUT_BOX"} inline type={"radio"} label={"INPUT_BOX"}
                                    name="inputtype"
                                    key={`INPUT_BOX`} value={"INPUT_BOX"} className={"mb-3"}/>
                        <Form.Check checked={valueInputType === "SLIDER"} inline type={"radio"} label={"SLIDER"}
                                    name="inputtype"
                                    key={`SLIDER`} value={"SLIDER"} className={"mb-3"}/>
                    </Form.Group>
                </Col>

        </>
    )
}

export default NumberInputConfig;
