import {combineReducers} from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import formReducer from "./formReducer";
import formInstanceReducer from "./formInstanceReducer";
import appStateReducer from "./appStateReducer";
import formCreationReducer from "./formCreationReducer";
import errorReducer from "./errorReducer";


const mainReducer = combineReducers({

    auth: authReducer,
    user: userReducer,
    forms: formReducer,
    formInstances: formInstanceReducer,
    appState: appStateReducer,
    formCreation:formCreationReducer,
    errors:errorReducer
})



export {mainReducer}
