import React from "react";
import {connectPage} from "./connector";
import Page from "../../components/layout/page";

const StartPage = (props) => {

    return (
        <Page>
            <div className="page">
                <h1>Well, Well</h1>
            </div>
        </Page>
    )
}

export default connectPage(StartPage)
