import {connect} from "react-redux";
import {loadByInstanceToken,saveDailyInstance} from "../../actions/dailyFormActions";
import {loadForm, requestFormInstancesForForm} from "../../actions/formActions";
import {deleteFormInstance} from "../../actions/formInstanceActions"
import {getFormById} from "../../selectors/formSelector";
import {getFormInstanceByInstanceToken,getFormInstanceById,getFormInstancesByForm} from "../../selectors/formInstanceSelector";
import {getTableColumnsByForm,getTableDataByForm} from "../../selectors/analyticsTableSelector";

function mapStateToProps(state){
    return {
        formById: (id) => getFormById(state,id),
        formInstanceByInstanceToken : (token) => getFormInstanceByInstanceToken(state,token),
        formInstanceById : (id) => getFormInstanceById(state,id),
        formInstancesByForm: (formId) => getFormInstancesByForm(state,formId),
        columnsByForm: (formId) => getTableColumnsByForm(state,formId),
        tableDataByForm : (formId) => getTableDataByForm(state,formId)
    }
}

function mapDispatchToProps(dispatch){

    return {
        loadByInstanceToken: (token) => dispatch(loadByInstanceToken(token)),
        saveDailyInstance: (token,data) => dispatch(saveDailyInstance(token,data)),
        loadForm : (id) => dispatch(loadForm(id)),
        loadFormInstancesForForm : (formId) => dispatch(requestFormInstancesForForm(formId)),
        deleteFormInstance : (id) => dispatch(deleteFormInstance(id))
    }
}

export const connectPage = connect(mapStateToProps,mapDispatchToProps);
