import {createAgent} from "../helper/axiosHelper";
import uniqid from "uniqid";
import {FORM_CREATE_SAVE_BASIC_FORM_DATA} from "./formActions";

const SAVED_SETTINGS = "SAVED_SETTINGS";

function saveSettings(settingsData) {
    return function (dispatch, getState) {

        const {auth} = getState();
        const agent = createAgent(auth);

        return new Promise((resolve, reject) => {
            agent.post("settings", settingsData)
                .then((res) => {
                    dispatch({
                        type: SAVED_SETTINGS,
                        settings: res.data
                    })

                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}


export {

    SAVED_SETTINGS,
    saveSettings
}
