import * as FORM_ACTION_TYPES from "../actions/formActions";
import produce from "immer"
import {SORT_FORM} from "../actions/formActions";
import arrayMove from 'array-move';
import {LOADED_DAILY_FORM_AND_INSTANCE} from "../actions/dailyFormActions";

const formTemplate = {
    id: null,
    user_id: null,
    title:null,
    created_at: null,
    updated_at:null,
    active:false,
    form_data:null,
    creation_status:null,
    last_send:null,
    daily_send_time:null,
    extra_time_data:null
}

const initForm = {
    all:[]
}

function formReducer(state = initForm, action) {

    if (action.type === FORM_ACTION_TYPES.CREATED_FORM) {

        const f = action.formdata;
        return produce(state, form => {
            let newForm = Object.assign({},{...formTemplate},f);
            form.all.push(newForm);
        })
    }

    if (action.type === FORM_ACTION_TYPES.FETCHED_ALL_FORMS) {

        const f = action.formdata;
        return produce(state, form => {

            form.all = f.map(d => {
                return Object.assign({},{...formTemplate},d);
            })

        })
    }

    if (action.type === FORM_ACTION_TYPES.FORM_CREATE_SAVE_BASIC_FORM_DATA) {
        const d = action.formData;
        const id = action.formId;

        return produce(state,form => {
            let idx = form.all.findIndex((i) => parseInt(i.id) === parseInt(id));
            if(idx === -1){
                return state;
            }
            let singleForm = form.all[idx];
            form.all[idx] = Object.assign({},singleForm,d);
        })
    }

    if (action.type === FORM_ACTION_TYPES.FORM_LOADED) {
        const d = action.formdata;
        const id = d.id;

        return produce(state,form => {
            let singleform = form.all.findIndex((i) => parseInt(i.id) === parseInt(id));
            if(singleform !== -1){
                form.all[singleform] = d;
            } else {
                form.all.push(Object.assign({},{...formTemplate},d));
            }
        })
    }

    if (action.type === FORM_ACTION_TYPES.ADD_FORM_ITEM) {

        const {inputType,values,formId,uid,_new} = action;

        return produce(state,newstate => {

            let idx = newstate.all.findIndex((i) => {
                return parseInt(i.id) === parseInt(formId)
            });

            let form = newstate.all[idx];
            form.form_data = form.form_data || [];
            let row = {inputType,uid,formId,_new,...values};
            form.form_data.push(row)
        });
    }

    if (action.type === FORM_ACTION_TYPES.SORT_FORM) {

        const {oldidx,newidx,formId} = action;

        return produce(state,newform => {

            let idx = newform.all.findIndex((i) => {
                return parseInt(i.id) === parseInt(formId)
            });

            let form = newform.all[idx];
            form.form_data = form.form_data || [];
            form.form_data = arrayMove(form.form_data,oldidx,newidx)
        });
    }

    if (action.type === FORM_ACTION_TYPES.PATCH_FORM_ITEM) {

        const {data,uid,formId} = action;

        return produce(state,newform => {

            let idx = newform.all.findIndex((i) => {
                return parseInt(i.id) === parseInt(formId)
            });

            let form = newform.all[idx];
            let formdata = form.form_data;
            let item = formdata.find(item => item.uid === uid);
            Object.assign(item,data);
        });
    }

    if (action.type === FORM_ACTION_TYPES.DELETE_FORM_ITEM) {
        const {uid,formId} = action;

        return produce(state,newform => {
            let idx = newform.all.findIndex((i) => {
                return parseInt(i.id) === parseInt(formId)
            });
            let form = newform.all[idx];
            let formdata = form.form_data;
            let fdidx = formdata.findIndex(item => item.uid === uid);

            if(fdidx !== -1){
                formdata.splice(fdidx,1);
            }
        });
    }

    if (action.type === FORM_ACTION_TYPES.DELETED_FORM) {
        const {data} = action;

        return produce(state,newform => {
            let idx = newform.all.findIndex((i) => {
                return parseInt(i.id) === parseInt(data)
            });

            if(idx !== -1){
                newform.all.splice(idx,1);
            }
        });

    }

    if(action.type === LOADED_DAILY_FORM_AND_INSTANCE){

        const formData = Object.assign({},{...formTemplate},action.data.form);

        return produce(state,form => {
            let idx = form.all.findIndex((i) => parseInt(i.id) === parseInt(formData.id));
            if(idx !== -1){
                form.all[idx] = formData;
            } else {
                form.all.push(formData);
            }
        })
    }

    return state;
}

export default formReducer;

