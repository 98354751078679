import React from "react";
import Form from "react-bootstrap/Form";

import Col from "react-bootstrap/Col"
import FormCheck from "react-bootstrap/FormCheck";

const NAME = "markdown_editor"

const MarkdownConfig = (props) => {
    const {item, onChange} = props;

    const value = item.config?.editor || "plain";

    const onLocalChange = (e) => {
        console.log(e)
        onChange({
            editor: e.target.value
        })
    }

    return null

}

export default MarkdownConfig;

