import React, {createRef} from "react";
import Page from "../../components/layout/page";
import {connectPage} from "./connector";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

const LoginPage = (props) => {
    const errors = props.errors?.errors;

    const fields = {
        email : createRef(),
        password: createRef()
    };

    const getValues = () => {
        let f = {}
        for(let key in fields){
            f[key] = fields[key].current.value;
        }
        return f;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let values = getValues();
        props.clearErrors();
        props.loginUser(values.email,values.password);
    };

    return (
        <Page>
            <Row>

                <Col className={"mt-5"} md={{span:6,offset:3}} lg={{span:4,offset:4}}>
                    <Card title={"Login"}>
                        <Card.Body>
                            <Form onSubmit={handleSubmit} className="login-form" style={{maxWidth: "100%"}}>

                                <Form.Group>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control name="mailadress" ref={fields.email} type="email" placeholder="name@example.com" isInvalid={!!errors?.email}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.email?.[0] || ""}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control name="passw" ref={fields.password} type={"password"} placeholder="12345 :)" isInvalid={!!errors?.password}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.password?.[0] || ""}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Button type="primary" className="login-form-button" style={{width: "100%"}}>
                                        Log in
                                    </Button>
                                    <Row>
                                        <Col>
                                            <Link className="login-form-forgot" to="/forgot-password" style={{float: "left"}}>
                                                Forgot password
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            Or <Link to="/register"> register now! </Link>
                                        </Col>

                                    </Row>

                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Page>
    )
}
export default connectPage(LoginPage);

