import TextAreaConfig from "../../components/formItems/textArea/textAreaConfig";
import NumberInputConfig from "../../components/formItems/numberInput/numberInputConfig";
import Col from "react-bootstrap/Col";
import React from "react";
import StarRatingConfig from "../../components/formItems/starRating/starRatingConfig";
import MarkdownConfig from "../../components/formItems/markdown/markdownConfig";
import SelectListConfig from "../../components/formItems/selectList/selectListConfig";
import SelectSingleConfig from "../../components/formItems/selectSingle/selectSingleConfig";
import BooleanConfig from "../../components/formItems/boolean/booleanConfig";
import SelectMultipleConfig from "../../components/formItems/selectMultiple/selectMultipleConfig";

export const FormItemConfigRows = (props) => {
    const {item, onChange} = props;
    if (!item) {
        return null;
    }
    const {inputType} = item;

    if (inputType === "simpleInput") {
        return null;
    } else if (inputType === "textArea") {
        return (
            <TextAreaConfig item={item} onChange={onChange}/>
        )
    } else if (inputType === "numberInput") {
        return (
            <NumberInputConfig item={item} onChange={onChange}/>
        )
    } else if (inputType === "markdown") {
        return (
            <MarkdownConfig item={item} onChange={onChange}/>
        )
    } else if (inputType === "rating") {
        return (
            <StarRatingConfig item={item} onChange={onChange}/>
        )
    } else if (inputType === "selectList") {
        return (
            <SelectListConfig item={item} onChange={onChange}/>
        )
    } else if (inputType === "selectSingle") {
        return (
            <SelectSingleConfig item={item} onChange={onChange}/>
        )
    } else if (inputType === "selectMultiple") {
        return (
            <SelectMultipleConfig item={item} onChange={onChange}/>
        )
    } else if (inputType === "image") {
        return (
            <Col>
                <p>upload oder cam ?</p>
            </Col>
        )
    } else if (inputType === "boolean") {
        return (
           <BooleanConfig item={item} onChange={onChange}/>
        )
    } else {
        return (
            <Col>
                <p>2 B Done</p>
            </Col>
        )
    }
}
