import {Redirect, Route} from "react-router";
import React from "react";

const GuestRoute = (props) => {
    const auth = props.auth;
    if (typeof auth === "undefined") {
        throw Error("Auth should be passed to GuestRoutes as prop")
    }
    if (auth.check === true) {
        return <Redirect
            to={{
                pathname: "/app",
                state: {from: props.location}
            }}
        />
    }
    return (
        <Route
            {...props}
        />
    ); 
};

const PrivateRoute = (props) => {
    const auth = props.auth;
    if (typeof auth === "undefined") {
        throw Error("Auth should be passed to PrivateRoute as prop")
    }
    if (auth.check === false) {
        return <Redirect
            to={{
                pathname: "/login",
                state: {from: props.location}
            }}
        />
    }
    return (
        <Route
            {...props}
        />
    );
};

export {GuestRoute,PrivateRoute}
