import {connect} from "react-redux";
import {logout} from "../../actions/authActions";
import {createBrowserHistory} from "history";


function mapStateToProps(state){
    return {
        authenticated: state.auth.check,
        history: createBrowserHistory(),
        errors: state.errors
    }
}

function mapDispatchToProps(dispatch){

    return {
        logout : () => dispatch(logout())
    }
}

export const connectLayout = connect(mapStateToProps,mapDispatchToProps);
