import React from "react";

const FxSection = (props) => {
    return (
        <section className={"fxsection mt-3"}>
            <div className="fxsectionlabel mb-2">{props.label}</div>
            <div className={"fxsectioncontent"}>
                {props.children}
            </div>
        </section>
    )
}

export default FxSection;
