import Axios from "axios";
import {getStore} from "../index";
import {logout} from "../actions/authActions";
import {setMainError} from "../actions/errorActions";


function handleErrors(mainError){
    const {status} = mainError;
    if(parseInt(status) === 401){
        const store = getStore();
        store.dispatch(logout());
    }
}

function showError(error){
    const raw = error;
    const status = error?.response?.status;
    const message = error?.response?.data?.message;
    const errors = error?.response?.data?.errors;
    const showMessage = (errors && Object.keys(errors).length > 0) ? Object.values(errors).map(e => e[0]) : [message];
    const mainError = {status,message,errors,showMessage,raw};

    const store = getStore();
    store.dispatch(setMainError(mainError));
    return mainError;
}

function createAgent(auth){
    console.log("###",process.env.NODE_ENV)
    const env = process.env.NODE_ENV;
    let baseUrl;
    if(env === "development"){
        baseUrl = "http://dailybackend.test/api/"
    } else {
        baseUrl = "https://api.yourdailyform.com/api/"
    }

    let axiosConfig = {
        baseURL:baseUrl,
        timeout: 2000,
        headers: {
            'Accept': "application/json",
        },
    }

    if(auth?.token){
        axiosConfig.headers.Authorization = "Bearer "+ auth.token
    }

    let agent =  Axios.create(axiosConfig);

    agent.interceptors.request.use(conf => {
        conf.params = {XDEBUG_SESSION_START:"PHPSTORM"};
        return conf;
    });

    agent.interceptors.response.use(function (response) {
        //console.log("AJAX RESPONSE: ",response)
        return response;
    }, function (error) {

        const mainError = showError(error);
        handleErrors(mainError);

        return Promise.reject(mainError);
    });

    return agent;
}

export {createAgent}
