import {connect} from "react-redux";
import {requestFormCreation,addFormItem,saveBasicFormData,saveBasicFormDataLocal,loadForm,sortForm,patchFormItem,deleteFormField,patchFormItemLocal} from "../../actions/formActions";
import {getFormById,hasFields,hasBasicFieldsFilledOut} from "../../selectors/formSelector";

function mapStateToProps(state){
    return {
        errors:state.errors,
        fullForm: (id) => getFormById(state,id),
        hasFields: (formId) => hasFields(state,formId),
        hasBasicFieldsFilledOut: (formId) => hasBasicFieldsFilledOut(state,formId)
    }
}

function mapDispatchToProps(dispatch){

    return {
        addForm : () => dispatch(requestFormCreation()),
        addFormItem : (type,values,formid) => dispatch(addFormItem(type,values,formid)),
        saveBasicFormData : (data,formId) => dispatch(saveBasicFormData(data,formId)),
        patchFormItem: (data,formId,uid) => dispatch(patchFormItem(data,formId,uid)),
        patchFormItemLocal : (data,formId,uid) => dispatch(patchFormItemLocal(data,formId,uid)),
        loadForm: (formId) => dispatch(loadForm(formId)),
        onSort: (oldidx,newidx,formId) => dispatch(sortForm(oldidx,newidx,formId)),
        deleteFormField: (formId,uid) =>  dispatch(deleteFormField(formId,uid)),
        saveBasicFormDataLocal : (data,formId) => dispatch(saveBasicFormDataLocal(data,formId))
    }
}

export const connectPage = connect(mapStateToProps,mapDispatchToProps);
