import React, {createRef, useEffect, useState} from "react";
import Page from "../../components/layout/page";
import {connectPage} from "./connector"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router";
import Form from "react-bootstrap/Form";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import FormPage from "../../components/layout/form_page";

const SettingsSection = (props) => {
    return (
        <Row>
            <Col className={"settings-section"}>
                <div className="settings-section-label">{props.label}</div>
                <div className={"settings-section-content"}>
                    {props.children}
                </div>
            </Col>
        </Row>

    )
}

const SettingsPage = (props) => {

    const {settings, theErrors, saveSettings} = props;
    const history = useHistory();
    console.log(settings)

    const oldPassWordRef = createRef();
    const newPasswordRef = createRef();

    const [settingsValues, setSettingsValues] = useState({
        name: "",
        email: "",
        timezone: ""
    });


    useEffect(() => {
        if (!settings) {
            return;
        }
        setSettingsValues({
            name: settings.name,
            email: settings.email,
            timezone: settings.timezone,
        })
    }, [settings]);



    const setBasicFormVals = (key, val) => {
        console.log(key,val)
        let data = Object.assign({}, settingsValues, {[key]: val})
        setSettingsValues(data);

    }

    const saveFullForm = () => {
        saveSettings(settingsValues);
    }

    const cancel = () => {
        alert("ja wohin dann ?")
    }

    const resetPassword = () => {
        console.log(newPasswordRef.current.value,oldPassWordRef.current.value)
    }

    return (
        <>
            <Page>
                <Row className={"mt-5 mb-3"}>
                    <Col>
                        <h1>Settings</h1>
                    </Col>
                </Row>

                <SettingsSection label={"Basic Info"}>

                    <Row gutter={35}>
                        <Col xs={{span: 6}}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name"
                                              isInvalid={!!theErrors?.name}
                                              onChange={(e) => {
                                                  setBasicFormVals("name", e.target.value)
                                              }}
                                              value={settingsValues.name || ""}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {theErrors?.name?.[0] || ""}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={{span: 6}}>
                            <Form.Group>
                                <Form.Label>E-Mail</Form.Label>
                                <Form.Control type="text" placeholder="your email"
                                              isInvalid={!!theErrors?.email}
                                              onChange={(e) => {
                                                  setBasicFormVals("email", e.target.value)
                                              }}
                                              value={settingsValues.email || ""}
                                              disabled
                                />
                                <Form.Control.Feedback type="invalid">
                                    {theErrors?.email?.[0] || ""}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={{span: 6}}>
                            <Form.Group>
                                <Form.Label>Timezone</Form.Label>
                                <br/>
                                <TimezonePicker
                                    style={{width:"400px"}}
                                    absolute={true}
                                    defaultValue={"Europe/Berlin"}
                                    value={settings.timezone}
                                    placeholder="Select timezone..."
                                    onChange={(timezone) => {
                                        setBasicFormVals("timezone", timezone)
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {theErrors?.timezone?.[0] || ""}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                </SettingsSection>
                <SettingsSection label={"Change Password"}>
                    <Row gutter={35}>
                        <Col xs={{span: 6}}>
                            <Form.Group>
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control ref={oldPassWordRef} type="password"
                                              isInvalid={!!theErrors?.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {theErrors?.password?.[0] || ""}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={{span: 6}}>
                            <Form.Group>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control ref={newPasswordRef} type="password"
                                              isInvalid={!!theErrors?.password}

                                />
                                <Form.Control.Feedback type="invalid">
                                    {theErrors?.password?.[0] || ""}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{span: 12, order: 1, offset:0}} md={{span: 12, order: 1}}>
                            <Button onClick={resetPassword} className={"mr-3"}
                                    variant={"danger"} disabled={false}>Reset Password</Button>
                        </Col>
                    </Row>
                </SettingsSection>
                <SettingsSection label={"Notifications"}>
                    Notifications : weekly ? updates ? daily ? reminders ?
                </SettingsSection>
                <SettingsSection label={"Account"}>
                    cancel account
                </SettingsSection>


                <Row>
                    <Col style={{marginTop: "25px", marginBottom: "50px"}} lg={{span: 12, order: 1, offset:0}} md={{span: 12, order: 1}}>
                        <Button onClick={saveFullForm} className={"mr-3"}
                                variant={"primary"} disabled={false}>Save</Button>
                        <Button onClick={cancel} className={"mr-3"}
                                variant={"danger"}>Cancel</Button>
                    </Col>
                </Row>

            </Page>

        </>
    )
}


export default connectPage(SettingsPage)
