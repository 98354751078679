import {connect} from "react-redux";
import {loginUser} from "../../actions/authActions";
import {clearErrors} from "../../actions/errorActions";

function mapStateToProps(state){

    return {
        errors:state.errors,
    }
}

function mapDispatchToProps(dispatch){

    return {
        loginUser : (mail,password) => dispatch(loginUser(mail,password)),
        clearErrors : () => {dispatch(clearErrors())}
    }
}

export const connectPage = connect(mapStateToProps,mapDispatchToProps);
