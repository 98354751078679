import {connect} from "react-redux";
import {requestFormCreation,requestAllForms, deleteForm,requestFormInstancesForForm} from "../../actions/formActions";

function mapStateToProps(state){
    return {
        errors:state.auth.errors,
        forms:state.forms,
        formInstances:state.formInstances?.all || []
    }
}

function mapDispatchToProps(dispatch){

    return {
        loadForms : (opts) => dispatch(requestAllForms(opts)),
        loadFormInstancesForForm : (formId) => dispatch(requestFormInstancesForForm(formId)),
        addNewForm : () => dispatch(requestFormCreation()),
        deleteForm : (id) => dispatch(deleteForm(id))
    }
}

export const connectPage = connect(mapStateToProps,mapDispatchToProps);
