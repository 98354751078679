import React from "react";
import Form from "react-bootstrap/Form";

const SelectMultipleRender = (props) => {
    const {onFieldValueChange, values, item} = props;
    const label = item.config?.label;
    const value = Array.isArray(values?.[item.uid]) ? values?.[item.uid] : [];
    const options = item.config?.selectOptions;

    const onChange = (e) => {
        let selectedUid = e.target.value;
        let checked = e.target.checked;
        let newValue;

        console.log(checked,selectedUid,value)

        if (checked) {
            newValue = value.slice();
            newValue.push(selectedUid);
        } else {
            newValue = value.slice();
            let pos = value.indexOf(selectedUid);
            newValue.splice(pos, 1);
        }

        if (onFieldValueChange) {
            onFieldValueChange(item.uid, item.formId, newValue)
        }
    }

    return (

        <Form.Group key={item.uid} onChange={onChange} value={value}>
            <Form.Label>{label}</Form.Label>
            <div className="input_type mt-2">
                {options ? options.map(o => {
                    return (<Form.Check inline checked={value.indexOf(o.uid) !== -1} name={item.uid}
                                        label={o.label} type={"checkbox"} id={o.uid} key={o.uid} value={o.uid}/>)
                }) : null}
            </div>
        </Form.Group>
    )

}

export default SelectMultipleRender;
