import React, {createRef, useEffect, useState} from "react";
import FormPage from "../../components/layout/form_page";
import {connectPage} from "./connector"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {FormItemSelectionModal} from "./FormItemSelectionModal";
import {useHistory, useParams} from "react-router";
import {FormPreviewList} from "./FormPreviewList";

const FormCreationPage = (props) => {
    const {
        addForm, addFormItem, saveBasicFormData, saveBasicFormDataLocal,
        patchFormItem, patchFormItemLocal, loadForm, onSort, deleteFormField, hasFields, hasBasicFieldsFilledOut
    } = props;
    const {errors, fullForm} = props;

    const {id} = useParams();
    const form = fullForm(id); // Ist gesamtes Form TODO ?
    const history = useHistory();

    console.log("theFormData",form)

    const [selectionModalVisible, setSelectionModalVisible] = useState(false);
    const [formItem, _setFormItem] = useState(null);
    const [basicFormValues, setBasicFormValues] = useState({
        title: "",
        daily_send_time: "",
        active: false
    });
    const setItemForEditing = (item) => {
        // Also wenn bearbeitet und dann wieder in preview gleich
        console.log(item,formItem)

        if(item === "cancel"){
            _setFormItem(null);
            return;
        }

        if (formItem === null && item !== "cancel") {
            _setFormItem(item);
            return;
        }

        if (formItem !== null && item !== "cancel") {
            patchFormItemLocal(form?.form_data?.[formItem.uid], id, formItem.uid)
            _setFormItem(item);
            return;
        }


    }

    if (errors?.status === 404) {
        history.push("/app");
    }

    useEffect(() => {
        loadForm(id)
    }, [])

    useEffect(() => {
        if (!form) {
            return;
        }
        setBasicFormValues({
            title: form.title,
            daily_send_time: form.daily_send_time,
            active: form.active
        })
    }, [form])


    const cancel = () => {
        history.push("/app");
    }

    const saveFullForm = () => {
console.log("Motherficker Yeah : saveFullForm")
        let data = Object.assign({},form,{creation_status: "saved"})
        saveBasicFormData(data, id)
            .then(() => {
                history.push("/app")
            })
            .catch(err => {
                console.log("I COULD NOT SAVE")
            })
    }



    const addSelectedItem = (type, values) => {
        addFormItem(type, values, id).then((dat) => {
            const {added_form_item, data} = dat;
            const item = data.form_data.find(i => i.uid === added_form_item);
            _setFormItem(item);
        });
        setSelectionModalVisible(false);
    }

    const theErrors = errors?.errors;

    const fields = {
        title: createRef(),
        daily_send_time: createRef(),
        active: createRef()
    };

    const setBasicFormVals = (key, val) => {
        console.log("SETBASICFORMVALS")
        let data = Object.assign({}, basicFormValues, {[key]: val})
        //saveBasicFormData(data,id)
        saveBasicFormDataLocal(data, id);
        //setBasicFormValues(Object.assign({}, basicFormValues, {[key]: val}));
    }

    const layoutInfoLg = hasFields(id) ? {span: 8, order: 1, offset:2} : {span: 8, offset:2};
    const layoutInfoMd = hasFields(id) ? {span: 12, order: 1} : {span: 12}

    return (
        <FormPage formId={id}>
            <Row>
                <Col lg={layoutInfoLg} md={layoutInfoMd}>
                    <Card title={"Basic Fields"} style={{marginBottom: "35px", marginTop: "35px"}}>
                        <Card.Header><strong>Basic Fields</strong></Card.Header>
                        <Card.Body layout={"vertical"}>
                            <Row gutter={35}>
                                <Col xs={{span: 6}}>
                                    <Form.Group>
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control ref={fields.title} type="text" placeholder="Form Title"
                                                      isInvalid={!!theErrors?.title}
                                                      onChange={(e) => {
                                                          setBasicFormVals("title", e.target.value)
                                                      }}
                                                      value={basicFormValues.title || ""}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {theErrors?.title?.[0] || ""}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col xs={{span: 6}}>
                                    <Form.Group>
                                        <Form.Label>Time</Form.Label>
                                        <Form.Control ref={fields.daily_send_time} type="time"
                                                      isInvalid={!!theErrors?.daily_send_time}
                                                      onChange={(e) => {
                                                          setBasicFormVals("daily_send_time", e.target.value)
                                                      }}
                                                      defaultValue={basicFormValues.daily_send_time || ""}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {theErrors?.daily_send_time?.[0] || ""}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col xs={{span: 12}}>
                                    <Form>
                                        <Form.Group>
                                            <Form.Switch checked={parseInt(basicFormValues['active']) === 1}
                                                         id="active-switch" onChange={(e) => {
                                                setBasicFormVals("active", parseInt(basicFormValues['active']) === 1 ? 0 : 1)
                                            }} inline type="switch" label={"active"} ref={fields.active}/>
                                        </Form.Group>
                                    </Form>
                                </Col>

                            </Row>

                        </Card.Body>
                    </Card>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-center">
                                <Button size={"lg"}
                                        onClick={() => setSelectionModalVisible(true)}
                                        variant={"primary"}
                                        disabled={!hasBasicFieldsFilledOut(id)}
                                        className={"addNewItemButton"}
                                >
                                    Add new form-item
                                </Button>
                            </div>

                        </Col>
                    </Row>

                </Col>
                <Col lg={{span: 8, order: 1, offset:2}} md={{span: 12, order: 2}}>
                    <Row style={{marginTop: "35px"}}>
                        <Col>

                            {hasFields(id) ?
                                <FormPreviewList deleteFormField={(uid) => deleteFormField(id, uid)}
                                                 onClickEdit={setItemForEditing}
                                                 onSort={(o, n) => onSort(o, n, id)}
                                                 formItems={form?.form_data}
                                                 editedItem={formItem}
                                                 onEditChange={(data, uid) => patchFormItemLocal(data, id, uid)}
                                /> :
                                null
                            }

                        </Col>
                    </Row>
                </Col>


            </Row>
            <Row>
                <Col style={{marginTop: "25px", marginBottom: "50px"}} lg={{span: 8, order: 1, offset:2}} md={{span: 12, order: 2}}>
                    <Button onClick={saveFullForm} className={"mr-3"}
                            variant={"primary"} disabled={!hasBasicFieldsFilledOut(id)}>Save</Button>
                    <Button onClick={cancel} className={"mr-3"}
                            variant={"danger"}>Cancel</Button>
                </Col>
            </Row>

            <FormItemSelectionModal visible={selectionModalVisible}
                                    addItem={addSelectedItem}
                                    closeModal={() => setSelectionModalVisible(false)}
            />

        </FormPage>
    )
}


export default connectPage(FormCreationPage)
