import SimpleInputRender from "./simpleInput/simpleInputRender";
import TextAreaRender from "./textArea/textAreaRender";
import NumberInputRender from "./numberInput/numberInputRenderBox";
import StarRatingRender from "./starRating/starRatingRender";

import React from "react";
import MarkdownRender from "./markdown/markdownRender";
//import SelectListRender from "./selectList/selectListRender";
import SelectSingleRender from "./selectSingle/selectSingleRender";
import BooleanRender from "./boolean/booleanRender";
import SelectMultipleRender from "./selectMultiple/selectMultipleRender";

export const getRenderedInputItem = (item, onFieldValueChange, values) => {
    let type = item.inputType;
    if (type === "simpleInput") {
        return (
            <SimpleInputRender key={item.uid} onFieldValueChange={onFieldValueChange} item={item} values={values}/>
        )
    }

    if (type === "textArea") {
        return (
            <TextAreaRender key={item.uid} onFieldValueChange={onFieldValueChange} item={item} values={values}/>
        )
    }

    if (type === "markdown") {
        return (
            <MarkdownRender key={item.uid} onFieldValueChange={onFieldValueChange} item={item} values={values}/>
        )
    }

    if (type === "numberInput") {

        return (
            <NumberInputRender key={item.uid} onFieldValueChange={onFieldValueChange} item={item} values={values}/>
        )
    }

    if (type === "rating") {
        return (
            <StarRatingRender key={item.uid} onFieldValueChange={onFieldValueChange} item={item} values={values}/>
        )
    }

    if (type === "selectList") {
        return (
            <><span>not available</span>
                {/*<SelectListRender key={item.uid} onFieldValueChange={onFieldValueChange} item={item} values={values}/>*/}
            </>
        )
    }

    if (type === "selectSingle") {
        return (
            <SelectSingleRender key={item.uid} onFieldValueChange={onFieldValueChange} item={item} values={values}/>
        )
    }

    if (type === "selectMultiple") {
        return (
            <SelectMultipleRender key={item.uid} onFieldValueChange={onFieldValueChange} item={item} values={values}/>
        )
    }

    if (type === "boolean") {
        return (
            <BooleanRender key={item.uid} onFieldValueChange={onFieldValueChange} item={item} values={values}/>
        )
    }

    return <div>{type} not available in getRenderInputItem.jsx</div>
}
