import * as ACTION_TYPES from "../actions/appStateActions";
import produce from "immer"

const initFormCreation = {

}

function formCreationReducer(state = initFormCreation, action) {


    return state;
}

export default formCreationReducer;

