import React, {useEffect, useState} from "react";

import {FormItemConfigRows} from "../FormItemConfigRows";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const FormItemEditForm = (props) => {
    const {item, onChange, patchFormItem, onClickEdit} = props;
    const [itemLocal,setItemLocal] = useState(item);

    useEffect(() => {
        setItemLocal(item)
    },[item])


    const setConfigField = (fieldName, value) => {
        let conf = Object.assign({}, itemLocal.config || {});
        conf[fieldName] = value;
        const newItem = Object.assign({}, itemLocal, {config: conf});
        console.log("setConfigField",conf,newItem)
        setItemLocal(newItem);
        //onChange(newItem, item.uid)
    }

    const onLabelChange = (e) => {
        const value = e.target.value;
        setLabelInvalid(false);
        setConfigField("label", value)
    }

    const mergeFullConfig = (conf) => {
        let newconf = Object.assign({}, itemLocal.config || {}, conf);
        console.log("item und newconf",item,newconf)
        const newItem = Object.assign({}, item, {config: newconf});
        console.log("newItem",newItem)
        setItemLocal(newItem);
        //onChange(newItem, item.uid)
    }

    const onClickSaveLocal = () => {
        console.log(item);
        if(itemLocal.config?.label === undefined || itemLocal.config?.label?.trim() === ""){
            setLabelInvalid(true);
        } else {
            onChange(itemLocal, item.uid)
            onClickEdit(null);
        }
    }
    const onClickCancelLocal = () => {
        onClickEdit("cancel");
    }

    const [labelInvalid,setLabelInvalid] = useState(false);

    if (itemLocal === undefined) return null;
    return (
        <>
        <div className="form-edit-item-content">
            <div className="indicator"></div>
            <div className={"form-preview-item-body"}>
                <Row>
                <Col sm={12}>
                    <h5>Edit {itemLocal.inputType}</h5>
                    <Form.Group>
                        <Form.Label>Label</Form.Label>
                        <br/>
                        <Form.Control isInvalid={labelInvalid} onChange={onLabelChange} value={itemLocal.config?.label || ""} type={"text"}/>
                        <Form.Control.Feedback type="invalid">
                            Field must have a label
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                </Row>
            <Row>

                <FormItemConfigRows item={itemLocal} onChange={mergeFullConfig}/>
                <div className={"edit-actions col-12"}>
                    <Button variant={"primary"} size={"sm"} onClick={onClickSaveLocal}>save settings</Button>
                    <Button variant={"danger"} size={"sm"} onClick={onClickCancelLocal}>cancel</Button>
                </div>
            </Row>
            </div>

        </div>

        </>
    )
}

export default FormItemEditForm;
