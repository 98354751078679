import React from "react";
import Form from "react-bootstrap/Form";


const SelectSingleRender = (props) => {
    const {onFieldValueChange, values, item} = props;
    const label = item.config?.label;
    const value = values?.[item.uid];

    const options = item.config?.selectOptions;
    const inputType = item.config?.inputType || "select"; // radio oder select

    const onChange = (e) => {
        let selectedUid = e.target.value;
        if (onFieldValueChange) {
            onFieldValueChange(item.uid, item.formId, selectedUid)
        }
    }

    if (inputType === "radio") {
        return (

            <Form.Group key={item.uid} onChange={onChange} value={value}>
                <Form.Label>{label}</Form.Label>
                <div className="input_type mt-2">
                {options ? options.map(o => {
                    return (<Form.Check inline defaultChecked={value === o.uid} name="selectsingleradio" label={o.label} type={"radio"} id={o.uid} key={o.uid} value={o.uid}/>)
                }) : null}
                </div>


            </Form.Group>
        )
    }
    if (inputType === "select") {
        return (

            <Form.Group key={item.uid}>
                <Form.Label>{label}</Form.Label>

                <Form.Control as="select" onChange={onChange} value={value}>
                    {
                        options ? options.map(o => {
                            return (
                                <option value={o.uid} key={o.uid}>{o.label}</option>
                            );
                        }) : null
                    }
                </Form.Control>

            </Form.Group>
        )
    }

    return null;

}

export default SelectSingleRender;
