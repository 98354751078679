import React from "react";
import Form from "react-bootstrap/Form";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";

const StarRatingRender = (props) => {
    const {onFieldValueChange,values,item} = props;
    const count = parseInt(item.config?.starCount || 5);
    const value = values?.[item.uid];


    const onChange = (number) => {

        if(onFieldValueChange){
            onFieldValueChange(item.uid,item.formId,number)
        }
    }

    return (

    <Form.Group>
        <Form.Label>{item.config?.label}  </Form.Label>
        <br/>
        <Rate value={value} count={count} onChange={onChange}/>
    </Form.Group>

    )
}

export default StarRatingRender;
