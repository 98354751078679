import {createAgent} from "../helper/axiosHelper";
import {fetchUser} from "./userActions";
import {clearErrors} from "./errorActions";

const RECEIVE_REQUEST_REGISTER_USER = "RECEIVE_REQUEST_REGISTER_USER";
const RECEIVE_REQUEST_LOGIN_USER = "RECEIVE_REQUEST_LOGIN_USER";
const SET_TOKEN_IN_STATE = "SET_TOKEN_IN_STATE";
const LOGOUT = "LOGOUT";


function onError(err){
    return function (dispatch, getState) {
        if (err.status === "Unauthorized") {
            dispatch(logout());
        }
    }
}

function setTokenInState(token) {
    return {
        type: SET_TOKEN_IN_STATE,
        token
    }
}

function checkIfStoredToken() {
    let token = localStorage.getItem("token");
    return function (dispatch, getState) {

        dispatch(setTokenInState(token, null))
        dispatch(fetchUser())
    }
}

function receiveRequestRegisterUser(data, errors = null) {
    return {
        type: RECEIVE_REQUEST_REGISTER_USER,
        data,
        errors
    }
}

function receiveRequestLoginUser(data, errors) {
    return {
        type: RECEIVE_REQUEST_LOGIN_USER,
        data,
        errors
    }
}

function registerUser(email, password, name) {
    return function (dispatch, getState) {
        const {auth} = getState();
        if (auth.token !== null) {
            return;
        }
        const agent = createAgent(auth);

        agent.post("auth/register", {email, password, name})
            .then((res) => {
                dispatch(receiveRequestRegisterUser(res.data))
                dispatch(fetchUser())
            }).catch(err => {

            dispatch(receiveRequestRegisterUser(null, err))
        })
    }
}

function loginUser(email, password) {

    return function (dispatch, getState) {
        const {auth} = getState();
        if (auth.token !== null) {
            return;
        }
        const agent = createAgent(auth);
        agent.post("auth/login", {email, password})
            .then((res) => {
                dispatch(receiveRequestLoginUser(res.data))
                dispatch(fetchUser())
            }).catch(err => {
                dispatch(receiveRequestLoginUser(null, err))

            })
    }
}

function logout() {
    return function (dispatch, getState) {
        dispatch(clearErrors());
        dispatch({type: LOGOUT})
    }

}

export {
    RECEIVE_REQUEST_REGISTER_USER,
    RECEIVE_REQUEST_LOGIN_USER,
    SET_TOKEN_IN_STATE,
    LOGOUT,
    registerUser,
    loginUser,
    logout,
    checkIfStoredToken,
    onError
}
