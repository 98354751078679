import {connect} from "react-redux";
import {loadByInstanceToken,saveDailyInstance} from "../../actions/dailyFormActions";

function mapStateToProps(state){
    return {
        formById: (id) => state.forms.all.find(f => f.id === parseInt(id)),
        formInstanceByInstanceToken : (token) => state.formInstances.all.find(f => f.token === token),
        formInstanceById : (id) => state.formInstances.all.find(f => f.id === id)
    }
}

function mapDispatchToProps(dispatch){
    console.log(dispatch,"!!!!")
    return {
        loadByInstanceToken: (token) => dispatch(loadByInstanceToken(token)),
        saveDailyInstance: (token,data) => dispatch(saveDailyInstance(token,data))
    }
}

export const connectPage = connect(mapStateToProps,mapDispatchToProps);
