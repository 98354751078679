import React, {useEffect, useState} from "react";

import {connectPage} from "./connector"
import {useParams} from "react-router";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {DailyFormInputList} from "../form_creation_page/DailyFormInputList";
import {createdAt} from "../../helper/timeHelper";
import moment from "moment";
import FormPage from "../../components/layout/form_page";
import Nav from "react-bootstrap/Nav";
import DataTable from 'react-data-table-component';
import Button from "react-bootstrap/Button";
import history from "../../helper/history";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const FormMainAnalyticsPage = (props) => {

    const {formById,loadForm,formInstancesByForm,loadFormInstancesForForm,columnsByForm,tableDataByForm,deleteFormInstance} = props;
    const {id} = useParams();

    const baseForm = formById(id);
    const columns = columnsByForm(id);
    const formInstances = formInstancesByForm(id);
    // Tabledata ist relevanter
    const tableData = tableDataByForm(id);
    const getFormInstanceById = (id) => {
        return formInstances.find(f => parseInt(f.id) == parseInt(id))
    }



    useEffect(() => {
        loadForm(id);
    }, [loadForm, id])

    useEffect(() => {
        if(baseForm !== undefined){
                loadFormInstancesForForm(baseForm.id);
        }
    },[loadFormInstancesForForm,baseForm])



    if (baseForm?.form_data === undefined || formInstances === undefined || columns === undefined) {
        return null;
    }

    console.log(history)

    columns.push({
        name: 'edit',
        button: true,
        cell: (row) => (<ButtonGroup>
            <Button size={"sm"} onClick={() => clickEdit(row)}>edit</Button>
            <Button size={"sm"} variant={"danger"} onClick={() => clickDelete(row)}>delete</Button>
        </ButtonGroup>),
    });

    const clickDelete = (e) => {
        deleteFormInstance(e.id)
    }

    const clickEdit = (e) => {
        const {token} = getFormInstanceById(e.id);
        history.push("/dailyform/edit/" + token + "/?aftersubmit=" + history.location.pathname);
    }

    return (
        <FormPage fluid={true}>
            <Row className={"mt-4"}>
                <Col md={{span: 3}} lg={{span: 2}} className={"sidebar"}>
                    <Nav variant={"pills"} className="flex-column" activeKey="/home">
                        <Nav.Item>
                            <Nav.Link href="/home">List all data</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-1">Link</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col>
                    {
                     <DataTable
                         dense={true}
                         responsive={true}
                         pagination={true}
                         paginationPerPage={50}
                         paginationRowsPerPageOptions={[14,50,200,0]}
                         defaultSortField={"day"}
                         defaultSortAsc={false}

                         columns={columns}
                         data={tableData}
                         title={false}
                         noHeader={true}
                         fixedHeader={false}
                         fixedHeaderScrollHeight={"100vh"}
                         subHeader={false}


                     />
                    }

                </Col>
            </Row>

        </FormPage>
    )
}

export default connectPage(FormMainAnalyticsPage);
