//import {createAgent} from "../helper/helper";
import {getStore} from "../index";
import * as AuthActions from "./authActions"
const ON_ERROR = "ON_ERROR";
const CLEAR_ERRORS = "CLEAR_ERRORS";

function setMainError(mainError) {
    const store = getStore();
    return function (dispatch, getState) {
        if(mainError.status === 401 && mainError.message === "Token not provided"){
            console.error("HIDDEN ERROR:",mainError);
            return;
        }
        store.dispatch({type:ON_ERROR,error:mainError});
        //store.dispatch(AuthActions.onError(mainError));

    }
}

function clearErrors(){
    return {
        type: CLEAR_ERRORS
    }
}

export {ON_ERROR,CLEAR_ERRORS ,setMainError,clearErrors}
