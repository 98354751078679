import * as ACTION_TYPES from "../actions/userActions";
import * as SETTINGS_TYPES from "../actions/settingsActions";
import produce from "immer"

const initAuth = {
    id: null,
    name: null,
    email: null,
    createdAt: null,
    verifiedEmail: false,
    settings:{timezone:"Europe/Berlin"},
    role:"client"
}

function authReducer(state = initAuth, action) {

    if (action.type === ACTION_TYPES.FETCHED_USER) {

        const usr = action.user;

        return produce(state, user => {
            user.id = usr.id;
            user.name = usr.name;
            user.email = usr.email;
            user.createdAt = usr.created_at;
            user.verifiedEmail = usr.verifiedEmail;
            user.settings = usr.settings;
            user.role = usr.role;
        })

    }

    if (action.type === SETTINGS_TYPES.SAVED_SETTINGS) {
        const usr = action.settings;
        return produce(state, user => {
            user.name = usr.name;
            user.email = usr.email;
            user.settings = usr.settings;
        })
    }


    return state;
}

export default authReducer;
