import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Form from  "react-bootstrap/Form";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import Showdown from "showdown"

const TextAreaRender = (props) => {

    const {onFieldValueChange,values,item} = props;
    const externVal = values?.[item.uid];

    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true
    });
    const [value, setValue] = React.useState("");
    const [selectedTab, setSelectedTab] = React.useState("write");

    const onChangePlain = (e) => {
        e.preventDefault();
        if(onFieldValueChange){
            onFieldValueChange(item.uid,item.formId,e.target.value)
        }
    }

    const onChangeHTML = (content) => {
        if(onFieldValueChange){
            onFieldValueChange(item.uid,item.formId,content)
        }
    }

    const onChangeMarkdown = (content) => {
        console.log(content);
        if(onFieldValueChange){
            onFieldValueChange(item.uid,item.formId,content)
        }
    }

    const getEditor = (item) => {
        const editor = item.config?.editor || "plain";
        if(editor === "plain"){
            return (
                <Form.Control as={"textarea"} value={externVal} onChange={onChangePlain} placeholder={"do it"}/>

            )
        }
        if(editor === "html"){

            return (
                <ReactQuill theme={"snow"} defaultValue={externVal}
                            onChange={onChangeHTML} />
            )
        }
    }


    return (
        <Form.Group key={item.uid}>
            <Form.Label>{item.config?.label}</Form.Label>
            {getEditor(item)}
        </Form.Group>
    )
}

export default TextAreaRender;
