import React, {useState,useEffect} from "react";
import Form from "react-bootstrap/Form";

const SimpleInputRender = (props) => {
    const {onFieldValueChange,values,item} = props;
    const externVal = values?.[item.uid];
    const [val,setVal] = useState();

    useEffect(() => {
        setVal(externVal)
    },[externVal])

    const onChange = (e) => {
        e.preventDefault();
        if(onFieldValueChange){
            onFieldValueChange(item.uid,item.formId,e.target.value)
        }
    }

    return (
        <Form.Group>
        <Form.Label>{item.config?.label}</Form.Label>
            <Form.Control key={item.uid} value={val || ""} size={"md"} type="text" onChange={onChange}/>
        </Form.Group>
    )
}

export default SimpleInputRender;
