import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {getRenderedInputItem} from "../../components/formItems/getRenderedInputItem";
import React from "react";

export const FormItemPreviewForm = (props) => {
    const {item, onClickEdit, deleteFormField} = props;
    return (
        <div className="form-preview-item-content">
            <div className={"form-preview-item-body"} style={{background: "#fff", display: "flex"}}>

                    <Col xs={12} style={{display: "block"}} className={""}>{getRenderedInputItem(item)}</Col>

            </div>
            <div className={"form-preview-item-actions"}>
                <div onClick={() => onClickEdit(item)} className="form-preview-item-edit py-2">
                    <span className="material-icons">settings</span>
                    <span className={"label"}>settings</span>
                </div>
                <div onClick={() => deleteFormField(item.uid)} className="form-preview-item-edit py-2">
                    <span className="material-icons">delete</span>
                    <span className={"label"}>delete</span>
                </div>

            </div>
        </div>
    )
}
