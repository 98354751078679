import {createAgent} from "../helper/axiosHelper";

const FETCHED_USER = "FETCHED_USER";

function fetchUser() {
    return function (dispatch, getState) {
        const {auth} = getState();
        const agent = createAgent(auth);
        agent.get("user")
            .then((res) => {
                dispatch({
                    type: FETCHED_USER,
                    user: res.data
                })
            })
            .catch(err => {
               console.log(err)
            })
    }
}

export {FETCHED_USER, fetchUser}
