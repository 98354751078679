import React, {useEffect} from "react";
import {connectPage} from "./connector"
import {useParams} from "react-router";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {DailyFormInputList} from "../form_creation_page/DailyFormInputList";
import {createdAt} from "../../helper/timeHelper";
import history from "../../helper/history";


const DailyFormPageHeader = (props) => {
    const {formInstance} = props;

    return (
        <>
            <Col className={"formpageheader mb-4"}>
                <h3>{formInstance.base_form?.title}</h3>
                <h4>{createdAt(formInstance.created_at)}</h4>
            </Col>

        </>
    )
}

const DailyFormPage = (props) => {

    const {formById, formInstanceByInstanceToken} = props;
    const {loadByInstanceToken} = props;
    const {token} = useParams();
    const formInstance = formInstanceByInstanceToken(token);
    const baseForm = formById(formInstance?.form_id);
    const search = new URLSearchParams(history.location.search);
    const aftersubmit = search.get("aftersubmit");

    useEffect(() => {
        loadByInstanceToken(token);
    }, [loadByInstanceToken, token])

    if (formInstance === undefined || baseForm === undefined) {
        return null;
    }

    const onSave = (token, itemsValues) => {
        props.saveDailyInstance(token, itemsValues).then(() => {
            if (aftersubmit === "index") {
                history.push("/dailyform/thanks")
                //history.push("/app")
            } else if(aftersubmit !== undefined){
                history.push(aftersubmit);
            }
            else {
                //history.push("/dailyform/thanks")
                history.goBack(1)
            }
        })
    }


    return (
        <div className={"fullformpage pb-5"}>
            <Container>
                <Row>
                    <DailyFormPageHeader formInstance={formInstance}/>
                </Row>
                <Row>
                    <Col>
                        <DailyFormInputList onSave={onSave} formInstance={formInstance}
                                            formItems={baseForm?.form_data || []}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default connectPage(DailyFormPage);
