import {connect} from "react-redux";
import {registerUser} from "../../actions/authActions";


function mapStateToProps(state){
    return {
        errors:state.auth.errors,
        fisch:"Hering"
    }
}

function mapDispatchToProps(dispatch){

    return {
        registerUser : (mail,password,name) => dispatch(registerUser(mail,password,name))
    }
}

export const connectPage = connect(mapStateToProps,mapDispatchToProps);

