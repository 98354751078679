import React from "react";
import {connectLayout} from "./connector";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import history from "../../helper/history";

const MainHeaderComponent = (props) => {
    const logout = () => {
        props.logout()
    }

    let key = history.location.pathname;

    const onSelect = (newkey) => {
        history.push(newkey);
    }


    return (
        <div className={"header"}>
            <div className="container-fluid header_upper">
                <div id="logo">
                    <div className="logo-inner">Daily Form</div>
                </div>
                <Nav onSelect={onSelect} activeKey={key}>
                    <Nav.Link hidden={props.authenticated} eventKey={"/"}>Home</Nav.Link>
                    <Nav.Link hidden={props.authenticated} eventKey={"/login"}>Login</Nav.Link>
                    <Nav.Link hidden={props.authenticated} eventKey={"/register"}>Register</Nav.Link>
                    <Nav.Link hidden={!props.authenticated} eventKey={"/app"}>Dashboard</Nav.Link>

                </Nav>
                <Nav onSelect={onSelect} className={"rightmenu d-flex"} style={{marginLeft: "auto"}}>
                    <Nav.Link hidden={!props.authenticated} eventKey={"/settings"}>Settings</Nav.Link>
                    <Button hidden={!props.authenticated} key="logout" onClick={logout} variant="outline-secondary" size={"sm"}
                            style={{marginLeft: "auto"}}>Log out</Button>
                </Nav>


            </div>
            {props.extra ?
                <div className="container-fluid header_extra">
                    {props.extra || null}
                </div> : null}
        </div>
    )
}

export default connectLayout(MainHeaderComponent);
