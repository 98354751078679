import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import React from "react";

const ErrorComp = (props) => {

    //const hiddenalert = props.errors?.showMessage === undefined;
    const msgs = props.errors?.showMessage ? props.errors?.showMessage.map((m, i) => {
        return (
            <Row style={{marginBottom: "15px", marginTop: "15px"}}>
                <Col key={i} span={8} offset={8}>
                    <Alert variant={"danger"}>{m}</Alert>
                </Col>
            </Row>
        )
    }) : null;

    return (
        <Container>
            {msgs}
        </Container>
    )
}

export default ErrorComp;
