import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import {getRenderedInputItem} from "../../components/formItems/getRenderedInputItem";
import {useParams} from "react-router";
import history from "../../helper/history";
const Item = ({item,onFieldValueChange,itemsValues}) => {

    return (
        <>{getRenderedInputItem(item,onFieldValueChange,itemsValues)}</>
    )
}

export const DailyFormInputList = (props) => {

    let items = props.formItems.slice(0);


    const {formInstance,onSave} = props;

    const [itemsValues, setItemsValues] = useState(formInstance?.input_data?.inputData || {});

    const onFieldValueChange = (uid, formId, value) => {

        let newVal = Object.assign({}, itemsValues, {[uid]: value});
        setItemsValues(newVal)
    }


    const onLocalSave = () => {

        if(onSave === undefined){
            return;
        }

        onSave(formInstance.token,itemsValues);
    }

    return (
        <div className={"mb-4"}>
            {items.map((value, index) => (
                <Item onFieldValueChange={onFieldValueChange} itemsValues={itemsValues} key={value.uid} index={index}
                      item={value}/>
            ))}

            <Button onClick={onLocalSave} type={"primary"}>Submit</Button>

        </div>
    )
}
