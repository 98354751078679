import React from "react";
import MainHeaderComponent from "./MainHeaderComponent";
import {connectLayout} from "./connector";
import Container from 'react-bootstrap/Container';
import ErrorComp from "./error_comp";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import history from "../../helper/history";
import {useParams} from "react-router";

const HeaderExtra = (props) => {

    const {id} = useParams();
    let key = history.location.pathname;
    let activeKey = key.split("/")[2];

    const onSelectTabNav = (newkey) => {
        history.push("/form/" + newkey + "/" + id);
    }

    return (<>

            <Row className={"formheader-tabs"}>
                <Col className={"mb-2"} md={{span:12,offset:0}}>
                    <Nav variant="pills" onSelect={onSelectTabNav} activeKey={activeKey} className="justify-content-center" >
                        <Nav.Item>
                            <Nav.Link eventKey="create"><span className="material-icons">settings</span><span>Create / Edit</span> </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="preview"><span className="material-icons">visibility</span><span>Preview</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="analyze"><span className="material-icons">show_chart</span><span>Analyze</span></Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </>
    )
}

const FormPage = (props) => {
    const {formId} = props;
    return (
        <div style={{"minHeight": "100vh"}}>

            <MainHeaderComponent extra={<HeaderExtra/>}/>
            <ErrorComp {...props}/>
            <Container fluid={props.fluid}>
                {props.children}
            </Container>

        </div>
    )
}

export default connectLayout(FormPage);
