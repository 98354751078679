import React, {useState, useEffect} from "react";
import FormCheck from "react-bootstrap/FormCheck";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

const COUNT = "starCount";


const StarRatingConfig = (props) => {
    const {item, onChange} = props;

    const [starCount, setStarCount] = useState(item.config?.[COUNT] || 5);

    const onUpdate = () => {

        const starRating = {
            [COUNT]: starCount,
        }

        onChange(starRating)
    }

    useEffect(() => {
        onUpdate();
    }, [starCount]);

    const onInputChange = (e) => {
        setStarCount(e.target.value);
        onUpdate();
    }

    return (
        <Col>
            <Form.Group label={"Range"}>
                <Form.Label>Number of Stars</Form.Label>
                <Form.Control type={"number"} min={2} max={10} onChange={(e) => onInputChange(e)} defaultValue={starCount} />
            </Form.Group>
        </Col>
    )
}

export default StarRatingConfig;
