import {connect} from "react-redux";

function mapStateToProps(state){
    return {

    }
}

export const connectPage = connect(mapStateToProps);

