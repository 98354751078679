import React from "react";
import Form from "react-bootstrap/Form";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const NumberInputRender = (props) => {

    const {onFieldValueChange,values,item} = props;

    const range = item.config?.range;
    const min = range ? range[0] : 0;
    const max = range ? range[1] : 100;
    const inputType = item.config?.inputType || "INPUT_BOX";
    const externVal = values?.[item.uid];

    const onChangeBox = (e) => {
        if(onFieldValueChange){
            onFieldValueChange(item.uid,item.formId,e.target.value)
        }
    }

    const onChangeSlider = (val) => {
        if(onFieldValueChange){
            onFieldValueChange(item.uid,item.formId,val)
        }
    }

    if (inputType === "INPUT_BOX") {
        return (
            <Form.Group>
                <Form.Label>{item.config?.label} <span style={{fontSize:"10px"}}>({min} - {max})</span> </Form.Label>
                <Form.Control onChange={onChangeBox} value={externVal} type={"number"} min={min} max={max}/>
            </Form.Group>
        )
    }

    if (inputType === "SLIDER") {
        const marks = {
            [min]: min,
            [max]: max
        }

        return (

            <Form.Group>
                <Form.Label>{item.config?.label} <span style={{fontSize:"10px"}}>({min} - {max})</span></Form.Label>
                <Slider onChange={onChangeSlider} defaultValue={externVal} marks={marks} tooltipVisible min={min} max={max}/>

            </Form.Group>
        )
    }
}

export default NumberInputRender;
