import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

const SimpleAlertModal = (props) => {
    const {title,text,confirmcallback,cancelcallback,confirmtext,canceltext,form} = props;

    const handleClose = () => cancelcallback();

    return (
        <Modal
            show={form !== null}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    {canceltext}
                </Button>
                <Button variant="danger" onClick={() => confirmcallback(form)}>{confirmtext}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SimpleAlertModal;
