import React, {useState} from "react";
import {FormItemSelectionCard} from "./FormItemSelectionCard";


export const FormItemSelectionList = (props) => {

    const {selectItem, selected} = props;
    const [thisShit, setThisShit] = useState(null);

    let trafo = {
        position:"relative",
        display: props.active ? "block" : "none"
    }

    return (
        <div ref={(e) => {
            setThisShit(e)
        }} style={{...trafo}}>
            <FormItemSelectionCard
                onClick={selectItem}
                title={"Simple Input"}
                tid={"simpleInput"}
                selected={selected}
                description={"If you need just one or two words"}/>
            <FormItemSelectionCard
                onClick={selectItem}
                title={"Text Area"}
                tid={"textArea"}
                selected={selected}
                description={"If it takes more then half a sentence..."}/>
            <FormItemSelectionCard
                onClick={selectItem}
                title={"Markdown"}
                tid={"markdown"}
                selected={selected}
                description={"Texting for nerds..."}/>
            <FormItemSelectionCard
                onClick={selectItem}
                title={"Number"}
                tid={"numberInput"}
                selected={selected}
                description={"Integers or Floating Point ..."}/>
            <FormItemSelectionCard
                onClick={selectItem}
                title={"Star Rating"}
                tid={"rating"}
                selected={selected}
                description={"Rate your stuff like a pro .."}/>

            {
                /*<FormItemSelectionCard
                onClick={selectItem}
                title={"Select List"}
                tid={"selectList"}
                selected={selected}
                description={"Select a predefined value"}/>
                */}
            <FormItemSelectionCard
                onClick={selectItem}
                title={"Select Single"}
                tid={"selectSingle"}
                selected={selected}
                description={"Choose your XYZ"}/>

            <FormItemSelectionCard
                onClick={selectItem}
                title={"Select Multiple"}
                tid={"selectMultiple"}
                selected={selected}
                description={"..this, this and this..(select and checkboxes)"}/>

            <FormItemSelectionCard
                onClick={selectItem}
                title={"Yes / No"}
                tid={"boolean"}
                selected={selected}
                description={"Do i need a boolean or not ?"}/>

            {/*

            <FormItemSelectionCard
                onClick={selectItem}
                title={"Image Capture"}
                tid={"imageCapture"}
                selected={selected}
                description={"Take a photo with your Webcam"}/>

            <FormItemSelectionCard
                onClick={selectItem}
                title={"Image Upload"}
                tid={"imageUpload"}
                selected={selected}
                description={"Upload a photo"}/>

            */}

        </div>
    )
}
