import React, {useState, useEffect} from "react";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FxSection from "../../reusables/FxSection";

const INPUTTYPE = "inputType";


const BooleanConfig = (props) => {
    const {item, onChange} = props;

    const inputType = item.config?.[INPUTTYPE] || "radio";

    const changeInputType = (e) => {
        onChange({
            [INPUTTYPE]: e.target.value
        });
    }

    return (
            <Col>
                <FxSection label={"Type of input"}>
                    <Form.Control as={"select"} onChange={changeInputType} value={inputType}>
                        <option value={"radio"}>Radios</option>
                        <option value={"checkbox"}>Checkbox</option>
                        <option value={"switch"}>Switch</option>
                    </Form.Control>
                </FxSection>
            </Col>
    )
}

export default BooleanConfig;
