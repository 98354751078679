import {sortableHandle} from "react-sortable-hoc";
import React, {useEffect, useState} from "react";
import FormItemEditForm from "./components/FormItemEditForm";
import {FormItemPreviewForm} from "./FormItemPreviewForm";


export const FormPreviewListItem = (props) => {

    const {item, mode, onClickEdit, deleteFormField,onEditChange } = props;

    const DragHandle = sortableHandle(() => <div className={"draggo"}>
        <span className="material-icons">drag_indicator</span>
    </div>);

    const [localItem,setLocalItem] = useState(null);

    useEffect(() => {
        //console.log("MODE CHANGED",item.uid)
    },[mode]);

    useEffect(() => {
        setLocalItem(item);
    },[item]);

    return (
        <div className="form-preview-item" size={"small"} style={{marginBottom: "20px"}}>
            <DragHandle/>

            {mode === "preview" ?

                <FormItemPreviewForm item={item} onClickEdit={onClickEdit} deleteFormField={deleteFormField}/>

                :

                <FormItemEditForm item={item} onClickEdit={onClickEdit} onChange={onEditChange}/>
            }


        </div>
    )
}
