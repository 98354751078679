import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col"
import uniqid from "uniqid";

import {SelectOptionsList} from "../../reusables/SelectOptions/SelectOptionsList";
import FxSection from "../../reusables/FxSection";

const SELECTOPTIONS = "selectOptions";
const INPUTTYPE = "inputType";


const SelectMultipleConfig = (props) => {
    const {item, onChange} = props;

    const options = item.config?.[SELECTOPTIONS] || [];


    const setOptions = (optionsArray) => {
        onChange({
            [SELECTOPTIONS]: optionsArray
        });
    }

    const addOption = () => {
        const newOption = {
            label: "",
            uid: uniqid()
        }
        setOptions([newOption, ...options]);
    }


    const optionUpdated = (uid, key, value) => {

        let optionIndex = options.findIndex(o => o.uid === uid);
        if (optionIndex !== -1) {
            let newOptions = options.slice();
            let newOption = {...options[optionIndex]}
            newOption[key] = value
            newOptions.splice(optionIndex, 1, newOption);
            setOptions(newOptions);
        }
    }

    const optionDelete = (uid) => {
        let optionIndex = options.findIndex(o => o.uid === uid);
        if (optionIndex !== -1) {
            let newOptions = options.slice();
            newOptions.splice(optionIndex, 1);
            setOptions(newOptions);
        }
    }



    return (
        <Col>
            <FxSection label={"Selectable Options"}>
                <SelectOptionsList options={options} addOption={addOption} optionUpdated={optionUpdated}
                                   optionDelete={optionDelete}/>
            </FxSection>
        </Col>
    )
}

export default SelectMultipleConfig;
