import React from "react";

export const LabelValueView = (props) => {
    const {label, value, style} = props;
    return (
        <div style={style?.main} className="label_value_view">
            <span style={style?.label} className="label">{label}</span>
            <span style={style?.value} className="value">{value}</span>
        </div>
    )
}
export const LabelValueListView = (props) => {
    const {items} = props;

    let r = items.map(i => {
        const [label, value] = i;
        return (<LabelValueView key={label + value} style={{
            main: {display: "flex", justifyContent: "space-between", marginBottom: "5px"},
            label: {fontWeight: "bolder"}
        }} label={label} value={value}/>)
    });

    return (
        <div className="label_value_list">
            {r}
        </div>
    )
}
