import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {Link} from "react-router-dom";
import React from "react";
import {LabelValueListView} from "../../components/reusables/LabelValueHelpers";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {createdAt} from "../../helper/timeHelper";
import history from "../../helper/history";

export const FormIndexItem = (props) => {

    const {form, deleteForm, formInstances} = props;
    if (!form) return null;
    let instancesOfForm = formInstances?.filter(fi => parseInt(fi.form_id) === parseInt(form.id))
    return (
        <Card className={"mb-3"} style={{width: "100%", minWidth:"350px",maxWidth: "850px"}} key={form.id}>
            <Card.Header>
                {form.title}
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col style={{padding: "0px 25px"}} md={{span: 12, offset: 0}}>
                        <LabelValueListView
                            items={[
                                ["is active", parseInt(form.active) === 1 ? "active" : "not active"],
                                ["daily send time", form.daily_send_time],
                                ["created at", createdAt(form.created_at, false)],
                                ["created forms", instancesOfForm.length],
                                ["filled out forms", instancesOfForm.filter(f => f.state !== "fresh").length]
                            ]}
                        />
                    </Col>
                </Row>


            </Card.Body>
            <Card.Footer>
                <Button className={"mr-3"} as={Link} to={"/form/create/" + form.id}>Edit</Button>
                <Button variant={"danger"} onClick={() => deleteForm(form.id)}>delete</Button>
            </Card.Footer>
        </Card>
    )
}
