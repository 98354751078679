import {createAgent} from "../helper/axiosHelper";
import uniqid from "uniqid";

const LOADED_DAILY_FORM_AND_INSTANCE = "LOADED_DAILY_FORM_AND_INSTANCE";
const SAVED_DAILY_INSTANCE = "SAVED_DAILY_INSTANCE";

function loadByInstanceToken(token) {
    return function (dispatch, getState) {
        const {auth} = getState();
        const agent = createAgent(auth);

        return new Promise((resolve, reject) => {
            agent.get("dailyform/getall/"+token)
                .then((res) => {
                    dispatch({
                        type: LOADED_DAILY_FORM_AND_INSTANCE,
                        data: res.data
                    })
                    resolve(res.data);

                })
                .catch(err => {

                    //dispatch({})
                    reject(err);
                })
        });
    }
}


function saveDailyInstance(token,data) {
    return function (dispatch, getState) {
        const {auth} = getState();
        const agent = createAgent(auth);

        return new Promise((resolve, reject) => {
            agent.patch("dailyform/"+token,{data})
                .then((res) => {
                    dispatch({
                        type: SAVED_DAILY_INSTANCE,
                        data: res.data
                    })
                    resolve(res.data);

                })
                .catch(err => {

                    reject(err);
                })
        });
    }
}



export {
    LOADED_DAILY_FORM_AND_INSTANCE,
    SAVED_DAILY_INSTANCE,
    loadByInstanceToken,
    saveDailyInstance
}
