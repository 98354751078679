import React, {useEffect, useState} from "react";

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {FormPreviewListItem} from "./FormPreviewListItem";


const SortableItem = SortableElement((props) => {

    const {item, editedItem, onClickEdit, deleteFormField, onEditChange} = props;


    return (
        <FormPreviewListItem mode={editedItem?.uid === item?.uid ? "edit" : "preview"}
                             item={item}
                             onClickEdit={onClickEdit}
                             deleteFormField={deleteFormField}
                             onEditChange={onEditChange}
        />)
});


const SortableList = SortableContainer((props) => {

    const {items} = props;

    if (items === null || items === undefined || items.length === 0) {
        return null;
    }
    return (
        <div>
            {items.map((item, index) => (
                <SortableItem key={item.uid} index={index} item={item} {...props} />
            ))}
        </div>
    )
});

export const FormPreviewList = (props) => {
        const {onClickEdit, editedItem, deleteFormField, onEditChange, formItems} = props;
        if (formItems === undefined || formItems?.length < 1) {
            return null
        }
        let items = formItems.slice(0);

        const onSortEnd = ({oldIndex, newIndex}) => {
            props.onSort(oldIndex, newIndex)
        }

        return (
            <SortableList useDragHandle={true}
                          transitionDuration={0}
                          lockAxis={"y"}
                          items={items}
                          onSortEnd={onSortEnd}
                          {...props}
            />
        )
    }
;
