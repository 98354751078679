import React, {useEffect, useState} from "react";

import {connectPage} from "./connector"
import {useParams} from "react-router";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {DailyFormInputList} from "../form_creation_page/DailyFormInputList";
import {createdAt} from "../../helper/timeHelper";
import moment from "moment";
import FormPage from "../../components/layout/form_page";


const DailyFormPageHeader = (props) => {
    const {baseForm} = props;

    return (
        <>
            <Col className={"formpageheader mb-4"}>
                <h3>{baseForm?.title}</h3>
                <h4>{createdAt(Date.now())}</h4>
            </Col>

        </>
    )
}

const FormPreviewPage = (props) => {

    const {formById, createDummyInstanceByForm, loadForm} = props;
    const {id} = useParams();

    const baseForm = formById(id);

    useEffect(() => {
        loadForm(id);
    }, [loadForm, id])


    let date = moment("now").format();
    console.log(date)

    return (
        <FormPage>
            <div className={"fullformpage"}>
                <Container>
                    <Row>
                        <DailyFormPageHeader baseForm={baseForm}/>
                    </Row>
                    <Row>
                        <Col>
                            <DailyFormInputList onSave={null} formInstance={null}
                                                formItems={baseForm?.form_data || []}/>
                        </Col>
                    </Row>

                </Container>
            </div>

        </FormPage>
    )
}

export default connectPage(FormPreviewPage);
