import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, createStore} from "redux";
import thunk from 'redux-thunk';
import {mainReducer} from "./reducers/mainReducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import {checkIfStoredToken} from "./actions/authActions";
import jquery from "jquery";

window.jQuery = window.jquery = window.$ = jquery;

const store = createStore(mainReducer,composeWithDevTools(
    applyMiddleware(thunk),
));

store.dispatch(checkIfStoredToken());

ReactDOM.render(<App store={store}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();




function getStore(){
    return store;
}

function getState(){
    return store.getState();
}

export {getState,getStore}
