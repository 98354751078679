import { createSelector } from 'reselect'

export const getFormInstanceByInstanceToken = (state,token) => {
    return state.formInstances.all.find(f => f.token === token)
}

export const getFormInstanceById = (state,id) => {
    return state.formInstances.all.find(f => f.id === id)
}

export const getFormInstancesByForm = (state,formId) => {
    return state.formInstances?.all.filter(f => parseInt(f.form_id) === parseInt(formId));
}
