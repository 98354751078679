import Form from "react-bootstrap/Form";
import React from "react";
import {sortableHandle} from "react-sortable-hoc";

export const SelectOptionsListItem = (props) => {
    const {option, optionUpdated, onDelete} = props;
    const {label, uid} = option;

    const onChangeLabel = (e) => {
        optionUpdated(uid, "label", e.target.value);
    }

    const deleteRow = () => {
        console.log("okay, sollte deleten")
        onDelete(uid)
    }
    const DragHandle = sortableHandle(() => <div className={"optiondraggo"}>
        <span className="material-icons">drag_indicator</span>
    </div>);

    return (
        <div className={"selectoptionlistitem mb-1 d-flex flex-row"}>
            <DragHandle/>
            <Form.Control placeholder="option y" type={"text"} value={label} onChange={onChangeLabel}/>
            <div className="actions">
                <div onClick={deleteRow} className="delete-action">
                    <span className="material-icons">delete</span>
                </div>
            </div>
        </div>
    )

}
