import { createSelector } from 'reselect'

const user = state => state.user;
const userSettings = state => state.user?.settings;

export const settings = createSelector(
    [user,userSettings],
    (user,userSettings) => {

        console.log(userSettings)

        return {
            name:user?.name,
            email:user?.email,
            timezone:userSettings?.timezone

        }
    }

)


