import { createSelector } from 'reselect'

export const getFormById = (state,id) => {
    return state.forms.all.find(f => f.id === parseInt(id));
}

export const hasFields = (state,id) => {
    let form = getFormById(state,id);
    let fields = form?.form_data;
    return (fields && fields.length > 0)
}

export const hasBasicFieldsFilledOut = (state,id) => {
    let form = getFormById(state,id);

    let okTitle = form?.title?.length > 0 && form.title.trim().length > 0;
    let okDate = !!form?.daily_send_time;

    return okTitle && okDate;
}
