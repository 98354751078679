import React, {useState, useEffect} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col"
import FormCheck from "react-bootstrap/FormCheck";
import Button from "react-bootstrap/Button";
import uniqid from "uniqid";

const SELECTOPTIONS = "selectOptions";

const SelectOptionsListItem = (props) => {
    const {option,optionUpdated,onDelete} = props;
    const {label,uid} = option;

    const onChangeLabel = (e) => {
        optionUpdated(uid,"label",e.target.value);
    }

    const deleteRow = () => {
        onDelete(uid)
    }

    return (
        <div className={"selectoptionlistitem mb-1 d-flex flex-row"}>
            <div className="optiondraggo">
                <span className="material-icons">drag_indicator</span>
            </div>
            <Form.Control placeholder="option x" type={"text"} value={label} onChange={onChangeLabel}/>
            <div className="actions">
                <div onClick={deleteRow} className="delete-action">
                    <span className="material-icons">delete</span>
                </div>
            </div>
        </div>
    )

}

const SelectOptionsList = (props) => {
    const {options, addOption,optionUpdated,optionDelete} = props;
    return (
        <>
            {options.map(o => <SelectOptionsListItem key={o.uid} option={o} optionUpdated={optionUpdated} onDelete={optionDelete}/>)}
            <br/>
            <Button onClick={addOption}>Add Option</Button>
        </>
    )

}

const SelectListConfig = (props) => {
    const {item, onChange} = props;

    const options = item.config?.[SELECTOPTIONS] || [];

    const setOptions = (optionsArray) => {
        onChange({
            [SELECTOPTIONS] : optionsArray
        });
    }

    const addOption = () => {
        const newOption = {
            label:"",
            uid:uniqid()
        }

        setOptions([newOption,...options]);

    }


    const optionUpdated = (uid,key,value) => {

        let optionIndex = options.findIndex(o => o.uid === uid);
        if(optionIndex !== -1){
            let newOptions = options.slice();
            let newOption = {...options[optionIndex]}
            newOption[key] = value
            newOptions.splice(optionIndex,1,newOption);
            setOptions(newOptions);
        }
    }

    const optionDelete = (uid) => {
        let optionIndex = options.findIndex(o => o.uid === uid);
        if(optionIndex !== -1){
            let newOptions = options.slice();
            newOptions.splice(optionIndex,1);
            setOptions(newOptions);
        }
    }

    return (
        <Col>
            <Form name={"blub"}>
                <FormCheck.Label>{"Possible Options"} </FormCheck.Label>
                <SelectOptionsList options={options} addOption={addOption} optionUpdated={optionUpdated} optionDelete={optionDelete}/>
            </Form>
        </Col>
    )
}

export default SelectListConfig;
