import * as ACTION_TYPES from "../actions/appStateActions";
import produce from "immer"

const initAppState = {
    formCreation:null
}

function appStateReducer(state = initAppState, action) {
    return state;
}

export default appStateReducer;

