import {connect} from "react-redux";
import {loadByInstanceToken,saveDailyInstance} from "../../actions/dailyFormActions";
import {loadForm} from "../../actions/formActions";

function mapStateToProps(state){
    return {
        formById: (id) => state.forms.all.find(f => f.id === parseInt(id)),
        formInstanceByInstanceToken : (token) => state.formInstances.all.find(f => f.token === token),
        formInstanceById : (id) => state.formInstances.all.find(f => f.id === id),

    }
}

function mapDispatchToProps(dispatch){

    return {
        loadForm : (id) => dispatch(loadForm(id)),
        loadByInstanceToken: (token) => dispatch(loadByInstanceToken(token)),

    }
}

export const connectPage = connect(mapStateToProps,mapDispatchToProps);
