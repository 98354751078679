import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Form from  "react-bootstrap/Form";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import Showdown from "showdown"

const MarkdownRender = (props) => {

    const {onFieldValueChange,values,item} = props;
    const externVal = values?.[item.uid];

    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true
    });

    console.log(values,item)

    const [selectedTab, setSelectedTab] = React.useState("write");
    const [localValue,setLocalValue] = React.useState(externVal);

    const onChangeMarkdown = (content) => {
        console.log("yey",content);
        setLocalValue(content);
        if(onFieldValueChange){
            onFieldValueChange(item.uid,item.formId,content)
        }
    }

    return (
        <Form.Group key={item.uid}>
            <Form.Label>{item.config?.label}</Form.Label>

            <ReactMde
                value={localValue}
                onChange={onChangeMarkdown}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={markdown => {
                    return Promise.resolve(converter.makeHtml(markdown))
                }}
            />
        </Form.Group>
    )
}

export default MarkdownRender;
