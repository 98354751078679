import React from "react";
import Form from "react-bootstrap/Form";

import Col from "react-bootstrap/Col"
import FormCheck from "react-bootstrap/FormCheck";

const NAME = "textArea_editor"

const TextAreaConfig = (props) => {
    const {item, onChange} = props;

    const value = item.config?.editor || "plain";

    const onLocalChange = (e) => {
        
        onChange({
            editor: e.target.value
        })
    }

    return (
        <Col>

            <Form name={"bla"} id={"bla"} onChange={onLocalChange}>
                <FormCheck.Label>{"Which Type"} </FormCheck.Label>
                <br/>
                <Form.Check defaultChecked={value === "plain"} inline type={"radio"} label={"plain textarea"} name="editortype" key={`plain`} value={"plain"} className={"mb-3"}/>
                <Form.Check defaultChecked={value === "html"} inline type={"radio"} label={"html editor"} name="editortype" key={`html`} value={"html"} className={"mb-3"}/>
            </Form>

        </Col>
    )
}

export default TextAreaConfig;

