import React from 'react';
import './styles/App.scss';
import {Provider} from "react-redux";

import {Route, Router, Switch} from "react-router";
import StartPage from "./pages/startpage/StartPage";
import RegisterPage from "./pages/registerpage/RegisterPage";
import AppPage from "./pages/form_index_page/FormIndexPage";
import SettingsPage from "./pages/settings_page/SettingsPage"
import LoginPage from "./pages/login_page/LoginPage";
import DailyFormPage from "./pages/daily_form_page/DailyFormPage";
import FormPreviewPage from "./pages/form_preview_page/FormPreviewPage";
import FormCreationPage from "./pages/form_creation_page/FormCreationPage";
import FormMainAnalyticsPage from "./pages/form_main_analytics_page/FormMainAnalyticsPage";
import history from "./helper/history";
import {GuestRoute, PrivateRoute} from "./components/routes/ExtraRouteComponents";
import {connect} from "react-redux";

import {CLEAR_ERRORS} from "./actions/errorActions";

function mapStateToProps(state) {
    return {
        auth: state.auth,
    }
}

const connectRouter = connect(mapStateToProps);

const DailyThankYouPage = () => {
    return (
        <h1>Soooo many thanks !</h1>
    )
}

const NoMatch = () => {
    return (
        <h1>Vollgas ins 404</h1>
    )
}

const App = (props) => {
    const store = props.store;
    history.listen((a) => {
        store.dispatch({type:CLEAR_ERRORS});
    });

    return (
        <Provider store={store}>
            <Router history={history} onUpdate={(a) => console.log("RUUUTA",a)}>
                <Switch>
                    <Route path="/" exact component={StartPage}/>
                    <Route path="/dailyform/thanks" exact component={DailyThankYouPage}/>
                    <Route path="/dailyform/:token" exact component={DailyFormPage}/>
                    <Route path="/dailyform/edit/:token" exact component={DailyFormPage}/>
                    <GuestRoute path="/register" exact component={RegisterPage} auth={props.auth}/>
                    <GuestRoute path="/login" exact component={LoginPage} auth={props.auth}/>
                    <PrivateRoute path="/form/create/:id" exact component={FormCreationPage} auth={props.auth}/>
                    <PrivateRoute path="/form/preview/:id" exact component={FormPreviewPage} auth={props.auth}/>
                    <PrivateRoute path="/form/analyze/:id" exact component={FormMainAnalyticsPage} auth={props.auth}/>
                    <PrivateRoute path="/app" exact component={AppPage} auth={props.auth}/>
                    <PrivateRoute path="/settings" exact component={SettingsPage} auth={props.auth}/>
                    <Route component={NoMatch} />
                </Switch>
            </Router>
        </Provider>
    );
}


export default connectRouter(App);
