import React, {useEffect,useState} from "react";
import Page from "../../components/layout/page";
import {connectPage} from "./connector"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router";
import {FormIndexItem} from "./FormIndexItem";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import SimpleAlertModal from "../../components/reusables/AlertModal/SimpleAlertModal";


const FormIndexPage = (props) => {

    const {forms, formInstances, loadForms, deleteForm, loadFormInstancesForForm, addNewForm} = props;

    const history = useHistory();

    const [deleteFormModalVisible,setDeleteFormModalVisible] = useState(null);

    useEffect(() => {
        loadForms({garbageCollect: true});
    }, [loadForms])

    useEffect(() => {
        if (forms !== undefined && forms.all.length > 0) {
            forms.all.forEach(f => {
                loadFormInstancesForForm(f.id);
            })
        }
    }, [loadFormInstancesForForm, forms, forms.all])

    const gotoFormCreation = () => {
        addNewForm()
            .then((response) => {
                history.push("/form/create/" + response.id);
            })
            .catch(err => {

            })
    }

    const _deleteFormModal = (formId) => {
        setDeleteFormModalVisible(formId);
    }

    const _deleteCallback = (formId) => {
        setDeleteFormModalVisible(null);
        deleteForm(formId);
    }

    return (
        <>
        <Page>
            <Row className={"mt-5"}>
                <Col>
                    <h1>All Forms</h1>
                </Col>


            </Row>

            <Row>
                <Col sm={{span:6}}>
                    <Card onClick={gotoFormCreation} className={"addFormCard"}>
                        <div className={"inner"}>
                            <span className="material-icons">add_circle_outline</span>
                            <span className={"addDescription"}>Add new Form !</span>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row style={{marginTop: "35px"}}>
                <Col md={{span: 12}}>
                    <CardDeck>


                        {
                            forms.all.filter(f => f.creation_status === "saved").map(f => (
                                <FormIndexItem key={f.id} form={f} deleteForm={_deleteFormModal}
                                               formInstances={formInstances}/>
                            ))
                        }

                    </CardDeck>
                </Col>
            </Row>
        </Page>
        <SimpleAlertModal title={"Delete Form"}
                          text={"This action can not be reversed"}
                          confirmcallback={_deleteCallback}
                          confirmtext={"Yes, delete"}
                          canceltext={"No, cancel"}
                          cancelcallback={() => setDeleteFormModalVisible(null)}
                          form={deleteFormModalVisible}/>

        </>
    )
}


export default connectPage(FormIndexPage)
