import { createSelector } from 'reselect'
import {getFormById} from "./formSelector";
import React from "react";
import {getFormInstancesByForm} from "./formInstanceSelector";
import {strip_tags} from "../helper/tools";

const getTypeByInputUid = (form,uid) => {
    let row = form.form_data.find(fd => fd.uid === uid);
    return row?.inputType
}

const polishData = (baseForm,data) => {
    if(typeof data === "undefined"){
        return data;
    }

    let out = {};
    for(let key in data){
        if(!data.hasOwnProperty(key)) continue;

        let type = getTypeByInputUid(baseForm,key);

        if(type === "boolean"){
            let value = parseInt(data[key]);
            out[key] = (value === 1) ? "yes" : "no";
        }

        else if(type === "selectList"){
            let value = data[key];
            let field = baseForm.form_data.find(fd => fd.uid === key);
            let config = field?.config;
            let options = config?.selectOptions;

            out[key] = options ? options.find(o => o.uid === value).label :  "---";
        }

        else if(type === "selectSingle"){
            let value = data[key];
            let field = baseForm.form_data.find(fd => fd.uid === key);
            let config = field?.config;
            let options = config?.selectOptions;

            out[key] = options ? options.find(o => o.uid === value).label :  "---";
        }

        else if(type === "selectMultiple"){
            let valueArray = data[key];

            let field = baseForm.form_data.find(fd => fd.uid === key);
            let config = field?.config;
            let options = config?.selectOptions;

            let labelArray = valueArray.map(uid => {
                return options.find(o => o.uid === uid).label
            })

            out[key] = labelArray.join(",")
        }

        else if(type === "textArea"){
            out[key] =  strip_tags(data[key])
        }
        else {
            out[key] = data[key];
        }
    }

    return out;
}

export const getTableDataByForm = (state,formId) => {
    let baseForm = getFormById(state,formId);
    if(baseForm === undefined) return;

    let formInstances = getFormInstancesByForm(state,formId);

    let tableData = formInstances.map(fi => {
        let hasInputData = fi.input_data?.inputData !== undefined;
        let id = fi.id;
        let day = fi.day;
        let _data = polishData(baseForm,fi.input_data?.inputData);

       // console.log(_data);

        let data = Object.assign({},{day,id,hasInputData},_data);
        return data;
    })

    return tableData;

}


export const getTableColumnsByForm = (state,formId) => {

    let baseForm = getFormById(state,formId);
    if(baseForm === undefined) return;

    let columns = baseForm?.form_data?.filter(item => item.config !== undefined).map(item => {
        let c = item.config;
        let obj = {
            name: c.label,
            selector: item.uid,
            sortable: true,
        }
        return obj;
    });

    if(columns === undefined){
        return [];
    }

    columns.unshift({name:"day",selector:"day"});


    return columns;
}
