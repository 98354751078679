import Media from "react-bootstrap/Media";
import React from "react";

export const FormItemSelectionCard = (props) => {

    let cn = "formItemSelectionCard " + ((props.selected === props.tid) ? "formItemSelectionCard_Selected" : "");
    cn += " mb-2";

    let style = props.selected === props.tid ? {background: "#eee"} : {background: "white"}
    Object.assign(style, {padding: "5px", border: "1px solid #eee"});

    return (
        <Media style={style} onClick={() => props.onClick(props.tid)}
               className={cn}
        >
            <img alt="" width={40} height={40} className={"mr-3"}/>
            <Media.Body>
                <strong>{props.title}</strong>
                <p style={{margin:0, fontSize: "0.8em"}}>{props.description}</p>
            </Media.Body>

        </Media>
    )
}
