import {createAgent} from "../helper/axiosHelper";
import uniqid from "uniqid";

const DELETE_FORMINSTANCE = "DELETE_FORMINSTANCE";
const DELETED_FORMINSTANCE = "DELETED_FORMINSTANCE";




function deleteFormInstance(id){
    return function (dispatch, getState) {
        const {auth} = getState();
        const agent = createAgent(auth);

        return new Promise((resolve, reject) => {
            agent.delete("forminstance/" + id)
                .then((res) => {
                    dispatch({
                        type: DELETED_FORMINSTANCE,
                        data: res.data
                    })

                    resolve(res.data);
                })
                .catch(err => {
                    //dispatch({})
                    reject(err);
                })
        });
    }
}



export {
    DELETED_FORMINSTANCE,
    DELETE_FORMINSTANCE,
    deleteFormInstance

}
