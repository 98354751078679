import {connect} from "react-redux";
import {saveSettings} from "../../actions/settingsActions";
import {settings} from "../../selectors/settingsSelector";

function mapStateToProps(state){
    return {
        errors:state.auth.errors,
        settings : settings(state)
    }
}

function mapDispatchToProps(dispatch){

    return {
        saveSettings : (settingsData) => dispatch(saveSettings(settingsData)),
    }
}

export const connectPage = connect(mapStateToProps,mapDispatchToProps);
