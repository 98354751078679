import Button from "react-bootstrap/Button";
import React from "react";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {SelectOptionsListItem} from "./SelectOptionsListItem";

const SortableOption = SortableElement((props) => {

    const {option, optionDelete, optionUpdated} = props;
    console.log(props)
    return (
        <SelectOptionsListItem key={option.uid} option={option} optionUpdated={optionUpdated}
                               onDelete={optionDelete}/>
    )
});


const SortableOptionList = SortableContainer((props) => {

    const {options} = props;

    if (options === null || options === undefined || options.length === 0) {
        return null;
    }
    return (
        <div>
            {options.map((o, index) => (
                <SortableOption key={o.uid} index={index} option={o} {...props} />
            ))}
        </div>
    )
});


export const SelectOptionsList = (props) => {
    const {options, addOption, optionUpdated, optionDelete} = props;

    const onSortEnd = ({oldIndex, newIndex}) => {
        props.onSort(oldIndex, newIndex)
    }

    return (
        <>
            <SortableOptionList useDragHandle={true}
                                transitionDuration={0}
                                lockAxis={"y"}
                                onSortEnd={onSortEnd}
                                {...props}
            />
            <br/>
            <Button onClick={addOption}>Add Option</Button>
        </>
    )

}
