import produce from "immer"
import {LOADED_DAILY_FORM_AND_INSTANCE, SAVED_DAILY_INSTANCE} from "../actions/dailyFormActions";
import {DELETED_FORMINSTANCE} from "../actions/formInstanceActions";
import {LOADED_FORMS_INSTANCES} from "../actions/formActions";
import {forEach} from "react-bootstrap/cjs/ElementChildren";

const initForm = {
    all: []
}

const formInstanceTemplate = {
    id: null,
    created_at: null,
    updated_at: null,
    day: null,
    form_id: null,
    input_data: [],
    token: null,
    state: null
}


function formInstanceReducer(state = initForm, action) {

    if (action.type === LOADED_DAILY_FORM_AND_INSTANCE) {
        const formInstanceData = Object.assign({}, {...formInstanceTemplate}, action.data.formInstance);

        return produce(state, instances => {
            let idx = instances.all.findIndex((i) => parseInt(i.id) === parseInt(formInstanceData.id));
            if (idx !== -1) {
                instances.all[idx] = formInstanceData;
            } else {
                instances.all.push(formInstanceData);
            }
        })
    }

    if (action.type === LOADED_FORMS_INSTANCES) {

        if (action.instances.length === 0) {
            return state;
        }

        return produce(state, instances => {

            action.instances.forEach((newInstance) => {
                let idx = instances.all.findIndex((i) => parseInt(i.id) === parseInt(newInstance.id));
                let newDat = Object.assign({}, {...formInstanceTemplate}, newInstance);
                if (idx !== -1) {
                    instances.all[idx] = newDat;
                } else {
                    instances.all.push(newDat);
                }
            })
        })
    }

    if (action.type === SAVED_DAILY_INSTANCE) {
        let instance = action.data?.formInstance;
        if (instance === undefined) {
            return state;
        }

        return produce(state, instances => {
            let idx = instances.all.findIndex((i) => parseInt(i.id) === parseInt(instance.id));
            let newDat = Object.assign({}, {...formInstanceTemplate}, instance);
            if (idx !== -1) {
                instances.all[idx] = newDat;
            } else {
                instances.all.push(newDat);
            }
        })
    }

    if (action.type === DELETED_FORMINSTANCE) {
        let instanceId = action.data;
        if (instanceId === undefined) {
            return state;
        }

        return produce(state, instances => {
            let idx = instances.all.findIndex((i) => parseInt(i.id) === parseInt(instanceId));
            if(idx !== -1){
                instances.all.splice(idx,1);
            }

        })
    }
    return state;
}

export default formInstanceReducer;

