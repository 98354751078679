import React from "react";

import MainHeaderComponent from "./MainHeaderComponent";
import {connectLayout} from "./connector";

import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import ErrorComp from "./error_comp"

const Page = (props) => {

    return (
        <div style={{"minHeight": "100vh"}}>

            <MainHeaderComponent extra={props.extra}/>
            <ErrorComp {...props}/>
            <Container>
                {props.children}
            </Container>

        </div>
    )
}

export default connectLayout(Page);
