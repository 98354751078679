import {createAgent} from "../helper/axiosHelper";
import uniqid from "uniqid";

const CREATED_FORM = "CREATED_FORM";
const FETCHED_ALL_FORMS = "FETCHED_ALL_FORMS";
const FORM_CREATE_SAVE_BASIC_FORM_DATA = "FORM_CREATE_SAVE_BASIC_FORM_DATA";
const FORM_LOADED = "FORM_LOADED";
const ADD_FORM_ITEM = "ADD_FORM_ITEM";
const SORT_FORM = "SORT_FORM";
const PATCH_FORM_ITEM = "PATCH_FORM_ITEM";
//const PATCH_FORM_ITEM_LOCAL = "PATCH_FORM_ITEM_LOCAL";
const DELETE_FORM_ITEM = "DELETE_FORM_ITEM";
const DELETED_FORM = "DELETED_FORM";
const LOADED_FORMS_INSTANCES = "LOADED_FORMS_INSTANCES";

function requestAllForms(opts) {
    return function (dispatch, getState) {
        const {auth} = getState();
        const agent = createAgent(auth);

        return new Promise((resolve, reject) => {
            agent.get("form", {data: {opts}})
                .then((res) => {

                    dispatch({
                        type: FETCHED_ALL_FORMS,
                        formdata: res.data
                    })

                    resolve(res.data);

                })
                .catch(err => {
                    const errors = err.response?.data?.error;
                    //dispatch({})
                    reject(errors);
                })
        });
    }
}

function loadForm(formId) {
    return function (dispatch, getState) {
        const {auth} = getState();
        const agent = createAgent(auth);

        return new Promise((resolve, reject) => {
            agent.get("form/" + formId)
                .then((res) => {

                    dispatch({
                        type: FORM_LOADED,
                        formdata: res.data
                    })

                    resolve(res.data);

                })
                .catch(err => {
                    //dispatch({})
                    reject(err);
                })
        });
    }
}

function requestFormCreation() {
    return function (dispatch, getState) {
        const {auth} = getState();
        const agent = createAgent(auth);

        return new Promise((resolve, reject) => {
            agent.post("form")
                .then((res) => {
                    dispatch({
                        type: CREATED_FORM,
                        formdata: res.data
                    })

                    resolve(res.data);

                })
                .catch(err => {

                    //dispatch({})
                    reject(err);
                })
        });
    }
}

function requestFormInstancesForForm(formId){
    return function (dispatch, getState) {
        const {auth} = getState();
        const agent = createAgent(auth);

        return new Promise((resolve, reject) => {
            agent.get("form/" + formId + "/instances")
                .then((res) => {
                    dispatch({
                        type: LOADED_FORMS_INSTANCES,
                        instances: res.data
                    })

                    resolve(res.data);

                })
                .catch(err => {
                    reject(err);
                })
        });
    }
}

function deleteForm(id){
    return function (dispatch, getState) {
        const {auth} = getState();
        const agent = createAgent(auth);

        return new Promise((resolve, reject) => {
            agent.delete("form/" + id,)
                .then((res) => {
                    dispatch({
                        type: DELETED_FORM,
                        data: res.data
                    })

                    resolve(res.data);

                })
                .catch(err => {

                    //dispatch({})
                    reject(err);
                })
        });
    }
}


function saveBasicFormDataLocal(data, formId){
    return function (dispatch, getState) {
        const {auth} = getState();
        //const agent = createAgent(auth);
        const mergedData = Object.assign({}, data)

        dispatch({
            type: FORM_CREATE_SAVE_BASIC_FORM_DATA,
            formData: mergedData,
            formId
        })
    }
}

function saveBasicFormData(data, formId , noValidation = false ) {

    return function (dispatch, getState) {
        const {auth} = getState();
        const agent = createAgent(auth);
        const mergedData = Object.assign({},data,{noValidation})
        console.log("saveBasicFormData im reducer:",mergedData)
        return new Promise((resolve, reject) => {

            agent.patch("form/" + formId, mergedData)
                .then((res) => {
                    dispatch({
                        type: FORM_CREATE_SAVE_BASIC_FORM_DATA,
                        formData: res.data,
                        formId
                    })

                    resolve(res.data);
                })
                .catch(err => {
                    //dispatch({})
                    reject(err);
                })
        });
    }
}

function addFormItem(type, values, formId) {
    return function (dispatch, getState) {
        const uid = uniqid();
        const {forms} = getState();
        const form = forms.all.find(f => parseInt(f.id) === parseInt(formId));
        const updated_at = form.updated_at;

        return new Promise((resolve, reject) => {
            dispatch({
                type: ADD_FORM_ITEM,
                inputType: type,
                uid: uid,
                values: values,
                _new:updated_at,
                formId
            });

            const {forms} = getState();
            const form = forms.all.find(f => parseInt(f.id) === parseInt(formId));
            resolve({data:form,added_form_item:uid})
        });
    }
}

function patchFormItemLocal(data, formId, uid) {
    return function (dispatch, getState) {
        console.log("PATCH FORMITEM LOCAL",data)
        dispatch({
            type: PATCH_FORM_ITEM,
            uid,
            data,
            formId
        });
    }
}

function patchFormItem(data, formId, uid) {

    return function (dispatch, getState) {

        return new Promise((resolve, reject) => {
            dispatch({
                type: PATCH_FORM_ITEM,
                uid,
                data,
                formId
            });

            const {forms} = getState();
            const form = forms.all.find(f => parseInt(f.id) === parseInt(formId));
            dispatch(saveBasicFormData(form, parseInt(formId),true))
                .then((data) => {
                    resolve(data)
                })
                .catch(err => reject(err))
        });
    }
}

function deleteFormField(formId, uid){
    return function (dispatch, getState) {

        return new Promise((resolve, reject) => {
            dispatch({
                type: DELETE_FORM_ITEM,
                uid,
                formId
            });

            const {forms} = getState();
            const form = forms.all.find(f => parseInt(f.id) === parseInt(formId));
            /*dispatch(saveBasicFormData(form, parseInt(formId)))
                .then((data) => {
                    resolve(data)
                })
                .catch(err => reject(err))*/
        });
    }
}


function sortForm(oldidx, newidx, formId) {
    return function (dispatch, getState) {

        return new Promise((resolve, reject) => {
            dispatch( {
                type: SORT_FORM,
                oldidx,
                newidx,
                formId
            });

            const {forms} = getState();
            const form = forms.all.find(f => parseInt(f.id) === parseInt(formId));
            dispatch(saveBasicFormData(form, parseInt(formId)))
                .then((data) => {
                    resolve(data)
                })
                .catch(err => reject(err))
        });
    }

}

export {
    DELETE_FORM_ITEM,
    CREATED_FORM,
    FETCHED_ALL_FORMS,
    FORM_CREATE_SAVE_BASIC_FORM_DATA,
    FORM_LOADED,
    ADD_FORM_ITEM,
    SORT_FORM,
    PATCH_FORM_ITEM,
    DELETED_FORM,
    LOADED_FORMS_INSTANCES,
    requestFormCreation,
    addFormItem,
    requestAllForms,
    saveBasicFormData,
    saveBasicFormDataLocal,
    loadForm,
    sortForm,
    patchFormItem,
    deleteFormField,
    deleteForm,
    requestFormInstancesForForm,
    patchFormItemLocal,

}
