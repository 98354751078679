import React, {createRef} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {connectPage} from "./connector";
import Page from "../../components/layout/page";

const RegisterPage = (props) => {

    const errors = props.errors?.errors;

    const fields = {
        email: createRef(),
        password: createRef(),
        password2: createRef(),
        name: createRef(),
        //confirm: createRef(),
        //agreement: createRef()
    };
    const getValues = () => {
        let f = {}
        for (let key in fields) {
            f[key] = fields[key].current.value;
        }
        return f;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log()
        const {email, password, name} = getValues();
        props.registerUser(email, password, name);
    }


    return (
        <Page>
            <Row>
                <Col className={"mt-5"} md={{span: 6, offset: 3}} lg={{span: 4, offset: 4}}>
                    <Card title={"Register"}>
                        <Card.Body>
                            <Form className={"register-form"} onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control ref={fields.email} type="email" placeholder="name@example.com"
                                                  isInvalid={!!errors?.email}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.email?.[0] || ""}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control ref={fields.password} type={"password"} placeholder="12345 :)"
                                                  isInvalid={!!errors?.password}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.password?.[0] || ""}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control ref={fields.password2} type={"password"} placeholder="12345 :)"
                                                  isInvalid={!!errors?.password2}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.password?.[0] || ""}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Nick Name</Form.Label>
                                    <Form.Control ref={fields.name} type="name" placeholder="your nickname"
                                                  isInvalid={!!errors?.name}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.email?.[0] || ""}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Button type="primary" className="login-form-button" style={{width: "100%"}}>
                                        Register !
                                    </Button>
                                </Form.Group>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Page>
    )
}



export default connectPage(RegisterPage);
