import React from "react";
import Form from "react-bootstrap/Form";
import FxSection from "../../reusables/FxSection"

const BooleanRender = (props) => {
    const {onFieldValueChange, values, item} = props;
    const label = item.config?.label;
    const value = parseInt(values?.[item.uid]);
    const inputType = item.config?.inputType || "radio"; // radio oder select

    const onChange = (e) => {
        let checked;

        if(e.target.type === "radio"){
            checked = e.target.value;
        }
        if(e.target.type === "checkbox"){
            checked = e.target.checked ? 1 : 0;
        }
        if(e.target.type === "switch"){
            checked = e.target.checked ? 1 : 0;
        }
        if (onFieldValueChange) {
            onFieldValueChange(item.uid, item.formId, checked)
        }
    }

    if (inputType === "radio") {
        return (

            <Form.Group key={item.uid} onChange={onChange} value={value}>
                <FxSection label={label}>
                    <Form.Check inline defaultChecked={value === 1} name={item.uid} label={"Yes"} type={"radio"}
                                value={1}/>
                    <Form.Check inline defaultChecked={value === 0} name={item.uid} label={"No"} type={"radio"}
                                value={0}/>
                </FxSection>
            </Form.Group>
        )
    }
    if (inputType === "checkbox") {
        return (
            <Form.Group key={item.uid} onChange={onChange}>
                <Form.Check defaultChecked={value === 1} type="checkbox" label={label}/>
            </Form.Group>
        )
    }

    if (inputType === "switch") {
        return (
            <Form.Group key={item.uid} onChange={onChange}>
                <Form.Check defaultChecked={value === 1} type="switch" label={label} id={item.uid}/>
            </Form.Group>
        )
    }

    return null;

}

export default BooleanRender;
