import * as ACTION_TYPES from "../actions/authActions";
import produce from "immer"
import {logout} from "../actions/authActions";

const initAuth = {
    token: null,
    errors:{},
    check:null
}

function authReducer(state= initAuth, action) {

    if (action.type === ACTION_TYPES.LOGOUT) {

        const nextState = produce(state,auth => {
            localStorage.removeItem("token")
            auth.token = null;
            auth.check = false;
        })

        return nextState;
    }

    if (action.type === ACTION_TYPES.SET_TOKEN_IN_STATE) {

        const token = action.token !== "undefined" ? action.token : null;
        const nextState = produce(state,auth => {
            auth.token = token;
            auth.check = token !== null ? true : false;
        })

        return nextState;
    }
    if (action.type === ACTION_TYPES.RECEIVE_REQUEST_REGISTER_USER) {
        const {errors,data} = action;
        const nextState = produce(state,auth => {
            auth.errors = null;
            if(errors){
                auth.errors = errors;
            }
            if(data){
                auth.token = data.access_token;
                auth.check = true;
                localStorage.setItem("token",data.access_token)
            }
        })

        return nextState;
    }

    if (action.type === ACTION_TYPES.RECEIVE_REQUEST_LOGIN_USER) {
        const {errors,data} = action;
        const nextState = produce(state,auth => {
            auth.errors = null;
            if(errors){
                auth.errors = errors;
            }
            if(data){
                auth.token = data.access_token;
                auth.check = true;
                localStorage.setItem("token",data.access_token)
            }
        })

        return nextState;
    }

    return state;
}

export default authReducer;
